import { createContext, useContext, useEffect, useState, useCallback } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    sendEmailVerification,
    onAuthStateChanged,
    signOut
} from "firebase/auth";

import { auth, db } from "../firebase-config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { UserData } from "../classes/UserData";
import { useNotificationContext, Notification } from "./NotificationContext";

/**
 * The DataUserContext provides the information about the authenticated user to all react components (see <UserAuthContext> tag in App())
 */

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {

    const { setNotification } = useNotificationContext();

    const [authUser, setAuthUser] = useState({});
    const [authUserData, setAuthUserData] = useState({});

    // trigger a login
    async function logIn(email, password) {
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (e) {
            setNotification(new Notification("error", "Login", "Failed to log in: " + e.message));
        }
    }

    // callback to fetch the authenticated user's data 
    const fetchUserData = useCallback(async () => {
        const docRef = doc(db, "users", authUser.uid);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const userData = docSnap.data();
            setAuthUserData(new UserData(authUser.uid, userData));
        } else {
            setAuthUserData(null);
            setNotification(new Notification("error", "Login", "Unknown user. Please try again."));
        }
    }, [authUser, setNotification]);

    // callback to trigger resending the email verification
    async function resendEmailVerification() {
        if (!authUser) {
            setNotification(new Notification("error", "Authentication", "No user to send verification again"));
            return;
        }
        try {
            await sendEmailVerification(auth.currentUser);
            setNotification(new Notification("info", "Email verification", "Sent email verification link to " + auth.currentUser.email));
        } catch (e) {
            setNotification(new Notification("error", "Login", "Failed to send email verification link: " + e.message));
        }
    }

    // trigger a sign-up (not used)
    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }

    // trigger a log-out
    async function logOut() {
        try {
            await signOut(auth);
            setNotification(new Notification("information", "Logout", "Successfully logged out"));
        } catch (e) {
            setNotification(new Notification("error", "Logout", "Failed to log out: " + e.message));
        }
    }

    // save the authenticated user's data
    async function saveAuthUser(userId, firstName, lastName, hasNewsletter) {
        await updateDoc(doc(db, "users", userId), {
            firstName: firstName,
            lastName: lastName,
            newsletterStatus: hasNewsletter ? "NewsletterYes" : "NewsletterNo"
        }).catch((e) => {
            setNotification(new Notification("error", "Save user data", "Error saving user data: " + e));
        });
        fetchUserData();
    }

    useEffect(() => {
        // subscribe to a change of authentication
        const subscribe = onAuthStateChanged(auth, (currentuser) => {
            setAuthUser(currentuser);
        });

        return () => {
            subscribe();
        };
    }, []);

    useEffect(() => {
        if (authUser && authUser.uid) {
            // once an authenticated user is available, fetch the user's data
            fetchUserData()
                .catch((e) => {
                    setNotification(new Notification("error", "Get user data", "Error retreiving user data: " + e));
                });
        } else {
            setAuthUserData(null);
        }
    }, [authUser, setNotification, fetchUserData]);

    return (
        <userAuthContext.Provider
            value={{ authUser, authUserData, resendEmailVerification, saveAuthUser, logIn, signUp, logOut }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}