import { db } from '../firebase-config';
import { setDoc, doc, deleteDoc } from 'firebase/firestore';
import { Notification } from "../context/NotificationContext";

// create a ReleaseData object from the raw Firestore release data
export class ReleaseData {
    constructor(uid, rawReleaseData) {
        this.id = uid;
        this.fullVersion = this.id;
        this.version = this.fullVersion.split(".");
        
        this.setValues(rawReleaseData.active, rawReleaseData.date.toDate(), rawReleaseData.expiration.toDate(), rawReleaseData.comment);
        if (rawReleaseData.configuration) {
            // release after configurations were introduced
            this.v = 2;
            this.base_url = rawReleaseData.base_url;
            this.os = rawReleaseData.os
            this.configuration = rawReleaseData.configuration;
            this.installer_name = rawReleaseData.installer_name;
        } else {
            // release BEFORE configurations were introduced
            this.v = 1;
            this.base_url = "https://nimagnafileshare.blob.core.windows.net/downloads";
            // os
            this.os = { 
                windows : rawReleaseData.url_windows?.length > 0, 
                mac_arm : rawReleaseData.url_mac_arm?.length > 0, 
                mac_intel : rawReleaseData.url_mac_intel?.length > 0
            };
            // configuration
            this.configuration = { 
                nimagna : true, 
                peoplefone : false
            };
            // installer name
            this.installer_name_windows = "NimagnaInstaller";
            this.installer_name_mac = "Nimagna App";
        }
    }

    url(os, configuration) {
        if (this.v===1) {
            // pre introduction of configurations
            if (os==="windows") {
                return this.base_url + `/${this.installer_name_windows}.${this.fullVersion}.exe`;
            } else if (os==="mac_arm") {
                return this.base_url + `/macos/${this.installer_name_mac}.${this.fullVersion}_arm64.dmg`;
            } else if (os==="mac_intel") {
                return this.base_url + `/macos/${this.installer_name_mac}.${this.fullVersion}_x86_64.dmg`;
            }
        }
        if (this.v===2) {
            // post introduction of configurations
            if (os==="windows") {
                return this.base_url + `/${this.installer_name[configuration]}.${this.fullVersion}.exe`;
            } else if (os==="mac_arm") {
                return this.base_url + `/macos/${this.installer_name[configuration]}.${this.fullVersion}_arm64.dmg`;
            } else if (os==="mac_intel") {
                return this.base_url + `/macos/${this.installer_name[configuration]}.${this.fullVersion}_x86_64.dmg`;
            }
        }
        return "";
    }

    setValues(isActive, releaseDate, expirationDate, comment) {
        this.active = isActive;
        this.date = releaseDate;
        this.dateString = this.date.toLocaleDateString();
        this.expirationDate = expirationDate;
        this.expirationDateString = this.expirationDate.toLocaleDateString();
        this.comment = comment;
    }

    // update values in Firebase and in the object itself
    async update(isActive, releaseDate, expirationDate, comment) {
        try {
            // write to Firebase
            const result = await setDoc(doc(db, "releases", this.id), {
                active: isActive,
                date: releaseDate,
                expiration: expirationDate,
                comment: comment
            }, { merge: true });
            // update object values
            this.setValues(isActive, releaseDate, expirationDate, comment);
            return result;
        } catch (e) {
            throw new Notification("error", "Save release", "Error saving release: " + e);
        }
    }

    async delete() {
        try {
            // write to Firebase
            return await deleteDoc(doc(db, "releases", this.id));
        } catch (e) {
            throw new Notification("error", "Delete release", "Error deleting release: " + e);
        }
    }
}