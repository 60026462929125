import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

import { Bar, Line, Doughnut } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    BarElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

export function BarChart({ title, datapoints, labels, color }) {

    const opt = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                //position: "bottom",
            },
            title: {
                display: false,
                text: title,
            },
        },
    };

    return (
        <>
            <Bar options={opt}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            data: datapoints,
                            backgroundColor: color,
                        }
                    ],
                }}
            />
        </>
    )
}

export function LineChart({ title, datapoints, labels, color }) {

    const opt = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
                //position: "bottom",
            },
            title: {
                display: false,
                text: title,
            },
        },
    };

    return (
        <>
            <Line options={opt}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            data: datapoints,
                            backgroundColor: color,
                            borderColor: color,
                        }
                    ],
                }}
            />
        </>
    )
}

export function DoughnutChart({ title, datapoints, labels, colors }) {

    const opt = {
        responsive: true,
        plugins: {
            legend: {
                position: "bottom",
            },
            title: {
                display: false,
                text: title,
            },
        },
    };

    return (
        <>
            <Doughnut options={opt}
                data={{
                    labels: labels,
                    datasets: [
                        {
                            label: title,
                            data: datapoints,
                            backgroundColor: colors,
                        }
                    ],
                }}
            />
        </>
    )
}