import { useTheme } from '../context/ThemeContext.js'
import { useUserAuth } from "../context/UserAuthContext";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import Icon from '@mdi/react';
import { mdiWeatherSunny, mdiMoonWaningCrescent, mdiAccountOutline, mdiLogout, mdiCogOutline } from '@mdi/js';

export function Navigation({ children }) {
    const navigate = useNavigate();

    const { theme, toggleTheme } = useTheme();
    const { authUser, authUserData, logOut } = useUserAuth();
    const [isActive, setisActive] = useState(false)

    // eslint-disable-next-line no-restricted-globals
    const isLocalhost = (location.hostname === "localhost");

    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    const devUser = authUserData && authUserData.userType === 100;
    if (authUser) {
        return (
            <>
                <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand has-background-primary-30">
                        <Link to='/home/view' className='navbar-item'><img
                            alt="Nimagna"
                            height="50"
                            src="/images/nimagna_icon_new.svg"
                            width="50"
                        /></Link>
                        <button className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} data-target="navbarBasicExample" onClick={() => { setisActive(!isActive); }}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </button>
                    </div>
                    <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id="navbarBasicExample">
                        <div className="navbar-start">
                            {
                                // show these only to developers!
                                devUser ?
                                    <>
                                        <Link className="navbar-item" to='/users'>Users</Link>
                                        <Link className="navbar-item" to='/releases/list'>Releases</Link>
                                        <Link className="navbar-item" to='/stages'>Stages</Link>
                                        <Link className="navbar-item" to='/statistics'>Statistics</Link>
                                    </>
                                    : ""
                            }
                            <button className="navbar-item is-hidden-tablet" onClick={toggleTheme} >
                                {theme === "light" ?
                                    <>
                                        <Icon path={mdiWeatherSunny} size={1} /> Light theme
                                    </>
                                    :
                                    <>
                                        <Icon path={mdiMoonWaningCrescent} size={1} /> Dark theme
                                    </>
                                }
                            </button>
                        </div>
                        <div className="navbar-end">
                            <div className="navbar-item has-dropdown is-hoverable">
                                <button className="navbar-link">
                                    <Icon path={mdiCogOutline} size={1} />
                                </button>

                                <div className="navbar-dropdown is-right">
                                    <div className="navbar-item"><Icon path={mdiAccountOutline} size={1} /> Logged in as: {authUserData?.displayName}</div>
                                    <div className="navbar-item is-clickable" onClick={handleLogout}><Icon path={mdiLogout} size={1} /> Logout</div>
                                    <div className="navbar-divider" />
                                    <div className="navbar-item is-clickable" onClick={toggleTheme}>
                                        {theme === "light" ?
                                            <><Icon path={mdiMoonWaningCrescent} size={1} /> Switch to Dark</>
                                            : <><Icon path={mdiWeatherSunny} size={1} /> Switch to Light</>
                                        } Theme
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </>
        );
    } else {
        return (
            <>
                <nav className="navbar is-primary" role="navigation" aria-label="main navigation">
                    <div className="navbar-brand has-background-primary-30">
                        <Link to='/login' className='navbar-item'><img
                            alt="Nimagna"
                            height="50"
                            src="/images/nimagna_icon_new.svg"
                            width="50"
                        /></Link>
                        <button className={`navbar-burger burger ${isActive ? 'is-active' : ''}`} data-target="navbarBasicExample" onClick={() => { setisActive(!isActive); }}>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </button>
                    </div>
                    <div className={`navbar-menu ${isActive ? 'is-active' : ''}`} id="navbarBasicExample">
                        <div className="navbar-start">
                            <Link className="navbar-item" to='/login'>{isLocalhost ? "Local" : ""} Login </Link>
                            <button className="navbar-item is-hidden-tablet" onClick={toggleTheme} >
                                {theme === "light" ?
                                    <>
                                        <Icon path={mdiWeatherSunny} size={1} /> Light theme
                                    </>
                                    :
                                    <>
                                        <Icon path={mdiMoonWaningCrescent} size={1} /> Dark theme
                                    </>
                                }
                            </button>
                        </div>
                        <div className="navbar-end">
                            <button className="button navbar-item is-primary m-1 is-hidden-mobile" onClick={toggleTheme}>
                                {theme === "light" ?
                                    <Icon path={mdiWeatherSunny} size={1} />
                                    : <Icon path={mdiMoonWaningCrescent} size={1} />
                                }
                            </button>
                        </div>
                    </div>
                </nav>
            </>
        );
    }

};

export function Loader() {
    return (
        <>
            <img src='/images/loader-botao.gif' width="20" alt="Loading..." />
        </>
    );
};