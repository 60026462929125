import { useEffect, useState } from "react";
import { useDataContext } from '../context/DataContext.js'
import { useUserAuth } from "../context/UserAuthContext";
import { BarChart, LineChart, DoughnutChart } from './Chart.js'
import { Icon } from '@mdi/react';
import { mdiAlertOutline } from '@mdi/js';

export default function Statistics({ children }) {

    const {
        users, refreshUsers,
        updateStatistics, collectLogins, collectSignups,
        loginStatisticsDate, serviceLoginStatisticsDate, signupStatisticsDate,
        loginsPerWeek, loginsPerMonth,
        serviceLoginsPerWeek, serviceLoginsPerMonth,
        signupsPerWeek, signupsPerMonth,
        userCountPerWeek, userCountPerMonth
    } = useDataContext();
    const { authUserData } = useUserAuth();

    const [osStats, setOsStats] = useState([]);
    const [newsletterStats, setNewsletterStats] = useState([]);
    const [verifiedStats, setVerifiedStats] = useState([]);
    const [isUpdatingStats, setIsUpdatingStats] = useState(false);

    const [weeksLabels] = useState(Array.from(Array(52).keys()).map((i) => { return (52 - i).toString(); }));
    const [monthsLabels] = useState(Array.from(Array(12).keys()).map((i) => { return (12 - i).toString(); }));

    useEffect(() => {
        let operatingSystem = Array(4).fill(0);
        let newsletter = Array(3).fill(0);
        let emailVerified = Array(3).fill(0);
        users.forEach((user) => {
            // os usage
            if (user.usingWindows && user.usingMacOS) {
                operatingSystem[2]++;
            }
            else if (user.usingWindows) {
                operatingSystem[0]++;
            }
            else if (user.usingMacOS) {
                operatingSystem[1]++;
            } else {
                operatingSystem[3]++;
            }

            // newsletter
            if (user.hasNewsletter === null) {
                newsletter[2]++;
            } else if (user.hasNewsletter) {
                newsletter[0]++;
            } else {
                newsletter[1]++;
            }

            // verified
            if (user.emailVerified === null) {
                emailVerified[2]++;
            } else if (user.emailVerified) {
                emailVerified[0]++;
            }
            else {
                emailVerified[1]++;
            }
        })
        setOsStats(operatingSystem);
        setNewsletterStats(newsletter);
        setVerifiedStats(emailVerified);
    }, [users])

    useEffect(() => {
        refreshUsers();
        updateStatistics(false);
    });

    let colors = {
        'red': 'rgba(225, 37, 27, 0.9)',
        'green': 'rgba(0, 148, 56, 0.9)',
        'yellow': 'rgba(224, 188, 27, 0.9)',
        'blue': 'rgba(85, 85, 230, 0.9)',
        'orange': 'rgba(255, 172, 28, 0.9)',
    }
    let now = new Date();
    let yesterday = new Date(now - 24 * 60 * 60 * 1000);

    return (
        <>
            <div className="section">
                <div className="block">
                    <h1 className="title is-1 has-text-centered">User Statistics</h1>
                    <div className="block has-text-centered">All about the Nimagna users.</div>
                </div>
                <div className="block">
                    <div className="grid">
                        <div className="cell" style={{ display: 'grid', height: '100%' }}>
                            <div className="notification is-info">
                                <h1 className="title is-4">Total users: {users?.length}</h1>
                                {signupsPerMonth[signupsPerMonth.length - 1]} new signup{signupsPerMonth[signupsPerMonth.length - 1] === 1 ? "" : "s"} last month.<br />
                                {loginsPerMonth[loginsPerMonth.length - 1]} login{loginsPerMonth[loginsPerMonth.length - 1] === 1 ? "" : "s"} last month.
                            </div>
                        </div>
                        <div className="cell" >
                            <div className="notification ">
                                <h1 className="title is-4">Last Update</h1>
                                <div className="columns">
                                    <div className="column">
                                        Logins: {loginStatisticsDate?.toLocaleDateString()}<br />
                                        Service: {serviceLoginStatisticsDate?.toLocaleDateString()}<br />
                                        Signups: {signupStatisticsDate?.toLocaleDateString()}<br />
                                    </div>
                                    {/* Show update button only if last update was more than one day ago */}
                                    {authUserData?.isAdmin && loginStatisticsDate && (yesterday > loginStatisticsDate) ?
                                        <div className="column">
                                            <button className={"button mt-2 is-danger is-outlined" + (isUpdatingStats ? " is-loading" : "")} onClick={async (e) => {
                                                setIsUpdatingStats(true);
                                                await collectSignups();
                                                await collectLogins();
                                                await updateStatistics(true);
                                                setIsUpdatingStats(false);
                                            }}>{!isUpdatingStats && <span className="has-text-danger"><Icon path={mdiAlertOutline} size={1} className="mr-2" /></span>}Update</button>
                                        </div>
                                        : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3">Signups per month</h1>
                        <BarChart title="Signups per month" datapoints={signupsPerMonth} labels={monthsLabels} color={colors['red']} />
                    </div>
                    <div className="column">
                        <h1 className="title is-3">Signups per week</h1>
                        <BarChart title="Signups per week" datapoints={signupsPerWeek} labels={weeksLabels} color={colors['red']} />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3">User count per month</h1>
                        <LineChart title="User count per month" datapoints={userCountPerMonth} labels={monthsLabels} color={colors['blue']} />
                    </div>
                    <div className="column">
                        <h1 className="title is-3">User count per week</h1>
                        <LineChart title="User count per week" datapoints={userCountPerWeek} labels={weeksLabels} color={colors['blue']} />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        Note: Logins not collected for developer users since release 24.2.
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3">Logins per month</h1>
                        <BarChart title="Logins per month" datapoints={loginsPerMonth} labels={monthsLabels} color={colors['green']} />
                    </div>
                    <div className="column">
                        <h1 className="title is-3">Logins per week</h1>
                        <BarChart title="Logins per week" datapoints={loginsPerWeek} labels={weeksLabels} color={colors['green']} />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3">Service Logins per month</h1>
                        <BarChart title="Logins per month" datapoints={serviceLoginsPerMonth} labels={monthsLabels} color={colors['orange']} />
                    </div>
                    <div className="column">
                        <h1 className="title is-3">Service Logins per week</h1>
                        <BarChart title="Logins per week" datapoints={serviceLoginsPerWeek} labels={weeksLabels} color={colors['orange']} />
                    </div>
                </div>
                <div className="columns">
                    <div className="column">
                        <h1 className="title is-3">Operating System</h1>
                        <DoughnutChart title="OS Usage" datapoints={osStats} labels={["Windows", "macOS", "Both", "Unknown"]} colors={[colors['green'], colors['red'], colors['blue'], colors['orange']]} />
                    </div>
                    <div className="column">
                        <h1 className="title is-3">Newsletter</h1>
                        <DoughnutChart title="Newsletter" datapoints={newsletterStats} labels={["Yes", "No", "Unknown"]} colors={[colors['green'], colors['red'], colors['orange']]} />
                    </div>
                    <div className="column">
                        <h1 className="title is-3">Email verified</h1>
                        <DoughnutChart title="Verified" datapoints={verifiedStats} labels={["Yes", "No", "Unknown"]} colors={[colors['green'], colors['red'], colors['orange']]} />
                    </div>
                </div>
            </div>
        </>
    );
};

