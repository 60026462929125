import { collection, getDocs, orderBy, query, setDoc, doc, deleteDoc, where, getCountFromServer, limit, updateDoc } from 'firebase/firestore';
import { createContext, useContext, useState } from "react";
import { db } from '../firebase-config';
import { UserData } from '../classes/UserData';
import { ReleaseData } from '../classes/ReleaseData';
import { StageData } from '../classes/StageData';
import { collectUserLogins, collectUserSignups } from './StatisticsCollector'
import { useNotificationContext, Notification } from "./NotificationContext";

/**
 * The DataUserContext provides the user information to all react components (see <DataContextProvider> tag in App())
 */

const dataContext = createContext();

function clamp(num, lower, upper) {
    return Math.min(Math.max(num, lower), upper);
}

export function DataContextProvider({ children }) {

    const { setNotification } = useNotificationContext();

    //////////////////////////////////////////////////////////////////////////////////////
    // User related data and operations
    //////////////////////////////////////////////////////////////////////////////////////
    const [users, setUsers] = useState([]);
    const [userLogins, setUserLogins] = useState([]);
    const [userLoginsUserId, setUserLoginsUserId] = useState(null);
    const [userStages, setUserStages] = useState([]);
    const [userStagesUserId, setUserStagesUserId] = useState(null);
    const [refreshUsersInProgress, setRefreshUsersInProgress] = useState(false);

    // all users
    async function refreshUsers() {
        if (refreshUsersInProgress || users.length > 0) return;
        setRefreshUsersInProgress(true);
        console.log("Refresh users");

        // get users collection 
        const usersCollectionRef = collection(db, 'users');
        const q = query(usersCollectionRef, orderBy("lastName", "asc"));
        await getDocs(q)
            .then(async (querySnapshot) => {
                querySnapshot.docs.forEach(async (doc) => {
                    // get Nimagna App login count
                    const loginsCountCollection = collection(db, `users/${doc.id}/logins`);
                    const loginsCountQuery = query(loginsCountCollection, where("application", "==", "Nimagna App"));
                    await getCountFromServer(loginsCountQuery)
                        .then(async (loginsCountSnapshot) => {
                            const loginsCount = loginsCountSnapshot.data().count;
                            // get Nimagna App last login 
                            const lastLoginCollection = collection(db, `users/${doc.id}/logins`);
                            const lastLoginQuery = query(lastLoginCollection, where("application", "==", "Nimagna App"), orderBy("timestamp", "desc"), limit(1));
                            await getDocs(lastLoginQuery)
                                .then(async (lastLoginSnapshot) => {
                                    var lastLogin = null;
                                    if (lastLoginSnapshot.size > 0) {
                                        lastLogin = lastLoginSnapshot.docs[0].data();
                                    }
                                    // get Nimagna Service last login 
                                    const lastServiceLoginQuery = query(lastLoginCollection, where("application", "==", "Nimagna Service"), orderBy("timestamp", "desc"), limit(1));
                                    await getDocs(lastServiceLoginQuery)
                                        .then(async (lastServiceLoginSnapshot) => {
                                            var lastServiceLogin = null;
                                            if (lastServiceLoginSnapshot.size > 0) {
                                                lastServiceLogin = lastServiceLoginSnapshot.docs[0].data();
                                            }
                                            // add new user to users array
                                            setUsers((users) => [
                                                ...users,
                                                new UserData(doc.id, doc.data(), loginsCount, lastLogin, lastServiceLogin)
                                            ])
                                            setRefreshUsersInProgress(false);
                                        }).catch((e) => {
                                            setNotification(new Notification("error", "Refresh user data", "Error getting last service login: " + e));
                                        });
                                }).catch((e) => {
                                    setNotification(new Notification("error", "Refresh user data", "Error getting last user login: " + e));
                                });
                        }).catch((e) => {
                            setNotification(new Notification("error", "Refresh user data", "Error getting last user login count: " + e));
                        });
                });
            }).catch((e) => {
                setNotification(new Notification("error", "Refresh user data", "Error getting users: " + e));
            });
    }

    // specific user logins
    async function fetchUserLogins(userId) {
        if (userId.length === 0) return;
        if (userId === userLoginsUserId) return;
        console.log("Fetch user logins...");
        // get user's logins collection
        const loginsCollectionRef = collection(db, `users/${userId}/logins`);
        const loginsCountQuery = query(loginsCollectionRef, where("application", "==", "Nimagna App"));
        await getDocs(loginsCountQuery)
            .then(async (querySnapshot) => {
                // combine id with data
                let loginsRaw = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setUserLogins(loginsRaw);
                setUserLoginsUserId(userId);
            }).catch((e) => {
                setNotification(new Notification("error", "Fetch user logins", "Error getting user's logins: " + e));
            });
    }
    // specific user stages + operations
    async function fetchUserStages(userId) {
        if (userId.length === 0) return;
        if (userId === userStagesUserId) return;
        console.log("Fetch user stages...")
        // get user's stages collection
        const stagesCollectionRef = collection(db, `users/${userId}/stages`);
        await getDocs(stagesCollectionRef)
            .then(async (querySnapshot) => {
                // combine id with data
                let stagesRaw = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setUserStages(stagesRaw);
                setUserStagesUserId(userId);
            }).catch((e) => {
                setNotification(new Notification("error", "Fetch user stages", "Error getting user's stages: " + e));
            });
    }

    async function addUserStage(userId, stageId) {
        await setDoc(doc(db, `users/${userId}/stages`, stageId), {
        }).catch((e) => {
            setNotification(new Notification("error", "Add user stage", "Error adding new user stage: " + e));
        });
        setUserStagesUserId(null);
        fetchUserStages(userId);
    }

    async function removeUserStage(userId, stageId) {
        await deleteDoc(doc(db, `users/${userId}/stages`, stageId), {
        }).catch((e) => {
            setNotification(new Notification("error", "Remove user stage", "Error removing user stage: " + e));
        });
        setUserStagesUserId(null);
        fetchUserStages(userId);
    }

    async function saveUser(id, firstName, lastName, createdDate, endOfTrialPeriodDate, userLevel, newsletterFlag, allowAsposeFlag) {
        await updateDoc(doc(db, "users", id), endOfTrialPeriodDate ? {
            firstName: firstName,
            lastName: lastName,
            userCreatedDate: createdDate,
            endOfTrialPeriod: endOfTrialPeriodDate,
            userType: userLevel,
            newsletterStatus: newsletterFlag ? "NewsletterYes" : "NewsletterNo",
            allowAspose: allowAsposeFlag ? allowAsposeFlag : true
        } : {
            firstName: firstName,
            lastName: lastName,
            userCreatedDate: createdDate,
            userType: userLevel,
            newsletterStatus: newsletterFlag ? "NewsletterYes" : "NewsletterNo",
            allowAspose: allowAsposeFlag ? allowAsposeFlag : true
        }).then(() => {
            const updatedUsers = users.map((user) => {
                if (user.id === id) {
                    user.update(firstName, lastName, createdDate, endOfTrialPeriodDate, userLevel, newsletterFlag, allowAsposeFlag ? allowAsposeFlag : true);
                }
                return user;
            });
            setUsers(updatedUsers);
        }).catch((e) => {
            setNotification(new Notification("error", "Save user", "Error saving user: " + e));
        })
    }

    async function updateTrialPeriod(id, trialPeriodDate) {
        // 2 is trial user
        const newUserType = 2;

        await updateDoc(doc(db, "users", id), {
            endOfTrialPeriod: trialPeriodDate,
            userType: newUserType,
        }).then(() => {
            const updatedUsers = users.map((user) => {
                if (user.id === id) {
                    user.update(user.firstName, user.lastName, user.createdDate, trialPeriodDate, newUserType, user.newsletterFlag, user.allowAsposeFlag);
                }
                return user;
            });
            setUsers(updatedUsers);
        }).catch((e) => {
            setNotification(new Notification("error", "Update trial period", "Error updating trial period: " + e));
        })
    }

    //////////////////////////////////////////////////////////////////////////////////////
    // Releases
    //////////////////////////////////////////////////////////////////////////////////////
    const [releases, setReleases] = useState([]);
    const [refreshReleasesInProgress, setRefreshReleasesInProgress] = useState(false);

    async function refreshReleases(force) {
        if (!force && (releases.length > 0 || refreshReleasesInProgress)) return;
        setRefreshReleasesInProgress(true);
        console.log("Refresh releases...");

        // get releases collection
        const releasesCollectionRef = collection(db, 'releases');
        const q = query(releasesCollectionRef, orderBy("date", "desc"));
        await getDocs(q)
            .then(async (querySnapshot) => {
                var releasesDataArray = [];
                querySnapshot.docs.forEach((doc) => {
                    releasesDataArray.push(new ReleaseData(doc.id, doc.data()));
                });
                setReleases(releasesDataArray);
                setRefreshReleasesInProgress(false);
                return true;
            }).catch((e) => {
                setNotification(new Notification("error", "Refresh releases", "Error getting release data: " + e));
            });
        return false;
    }

    async function createRelease(version, isPublic, isWindows, isMacOsArm, isMacOsIntel, isNimagna, isPeoplefone) {
        // minimal data
        var data = {
            active: isPublic,
            date: new Date(),
            expiration: new Date("2999-12-31")
        }
        // URLS for pre-configuration updates
        // Note: Before the introduction of the configurations, the app checked for the url_* values.
        const baseUrl = 'https://nimagnafileshare.blob.core.windows.net/downloads'
        if (isWindows) {
            data.url = `${baseUrl}/NimagnaInstaller.${version}.exe`;
            data.url_windows = `${baseUrl}/NimagnaInstaller.${version}.exe`;
        }
        if (isMacOsArm) {
            data.url_mac_arm = `${baseUrl}/macos/Nimagna%20App.${version}_arm64.dmg`;
        }
        if (isMacOsIntel) {
            data.url_mac_intel = `${baseUrl}/macos/Nimagna%20App.${version}_x86_64.dmg`;
        }
        // Data for post-configurations updates
        // Note: After the introduction of configurations, the app checks for the os, configuration, installer_name, and in particular the base_url values.
        data.os = { windows: isWindows, mac_arm: isMacOsArm, mac_intel: isMacOsIntel};
        data.configuration = { nimagna: isNimagna, peoplefone: isPeoplefone};
        data.installer_name = { nimagna: "NimagnaInstaller", peoplefone: "peoplefoneStudioInstaller"};
        data.base_url = baseUrl;
        await setDoc(doc(db, "releases", version), data).catch((e) => {
            setNotification(new Notification("error", "Save stage", "Error saving stage: " + e));
        });
        refreshReleases(true);
    }

    async function saveRelease(id, isActive, releaseDate, expirationDate, comment) {
        try {
            // find the release object to update
            const filteredRelease = releases.filter(release => release.id === id);
            if (filteredRelease.length === 1) {
                var releaseToUpdate = filteredRelease[0];
                // update the object
                const response = await releaseToUpdate.update(isActive, releaseDate, expirationDate, comment);
                // and replace it with the updated object in the array
                const updatedReleases = releases.map((release) => {
                    if (release.id === id) {
                        return releaseToUpdate;
                    } else {
                        return release;
                    }
                })
                setStages(updatedReleases);
                return response;
            } else {
                throw new Notification("error", "Save release", "Could not find release object " + id);
            }
        } catch (e) {
            console.log("catch save", e)
            if (e instanceof Notification) {
                setNotification(e);
            } else {
                setNotification(new Notification("error", "Save release", "error: " + e))
            }
        }
    }

    async function deleteRelease(id) {
        try {
            // find the release object to update
            const filteredRelease = releases.filter(release => release.id === id);
            if (filteredRelease.length === 1) {
                var releaseToDelete = filteredRelease[0];
                // update the object
                console.log(releaseToDelete);
                const response = await releaseToDelete.delete();
                // and remove it from the releases
                setReleases(releases.filter(release => release.id !== id));
                return response;
            } else {
                throw new Notification("error", "Delete release", "Could not find release object " + id);
            }
        } catch (e) {
            console.log("catch delete", e)
            if (e instanceof Notification) {
                setNotification(e);
            } else {
                setNotification(new Notification("error", "Delete release", "error: " + e))
            }
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////
    // Stages
    //////////////////////////////////////////////////////////////////////////////////////
    const [stages, setStages] = useState([]);
    const [refreshStagesInProgress, setRefreshStagesInProgress] = useState(false);

    async function refreshStages(force) {
        if (!force && (stages.length > 0 || refreshStagesInProgress)) return;
        setRefreshStagesInProgress(true);
        console.log("Refresh stages...")

        // get stages collection
        const stagesCollectionRef = collection(db, 'stages');
        const q = query(stagesCollectionRef, orderBy("user_level", "asc"));
        await getDocs(q)
            .then(async (querySnapshot) => {
                var stagesDataArray = [];
                querySnapshot.docs.forEach((doc) => {
                    stagesDataArray.push(new StageData(doc.id, doc.data()));
                });
                setStages(stagesDataArray);
                setRefreshStagesInProgress(false);
                return true;
            }).catch((e) => {
                setNotification(new Notification("error", "Refresh stages", "Error getting stage data: " + e));
            });
        return false;
    }

    async function saveStage(id, isPublic, filename, userLevel, minVersion, maxVersion) {
        try {
            // find the stage object to update
            const filteredStage = stages.filter(stage => stage.id === id);
            if (filteredStage.length === 1) {
                var stageToUpdate = filteredStage[0];
                // update the object
                const response = await stageToUpdate.update(isPublic, filename, userLevel, minVersion, maxVersion);
                // and replace it with the updated object in the array
                const updatedStages = stages.map((stage) => {
                    if (stage.id === id) {
                        return stageToUpdate;
                    } else {
                        return stage;
                    }
                })
                setStages(updatedStages);
                return response;
            } else {
                throw new Notification("error", "Save stage", "Could not find stage object " + id);
            }
        } catch (e) {
            console.log("catch save", e)
            if (e instanceof Notification) {
                setNotification(e);
            } else {
                setNotification(new Notification("error", "Save stage", "error: " + e))
            }
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////
    // Statistics
    //////////////////////////////////////////////////////////////////////////////////////
    const [loginStatisticsDate, setLoginStatisticsDate] = useState(null);
    const [loginsPerWeek, setLoginsPerWeek] = useState([]);
    const [loginsPerMonth, setLoginsPerMonth] = useState([]);
    const [serviceLoginStatisticsDate, setServiceLoginStatisticsDate] = useState(null);
    const [serviceLoginsPerWeek, setServiceLoginsPerWeek] = useState([]);
    const [serviceLoginsPerMonth, setServiceLoginsPerMonth] = useState([]);
    const [signupStatisticsDate, setSignupStatisticsDate] = useState(null);
    const [signupsPerWeek, setSignupsPerWeek] = useState([]);
    const [signupsPerMonth, setSignupsPerMonth] = useState([]);
    const [userCountPerWeek, setUserCountPerWeek] = useState([]);
    const [userCountPerMonth, setUserCountPerMonth] = useState([]);
    const [statisticsUpdateState, setStatisticsUpdateState] = useState(0);

    async function collectLogins() {
        console.log("Collect Logins");
        await collectUserLogins();
    }
    async function collectSignups() {
        console.log("Collect Signups");
        await collectUserSignups();
    }

    async function updateStatistics(force) {

        function monthDiff(dateFrom, dateTo) {
            return dateTo.getMonth() - dateFrom.getMonth() +
                (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
        }

        if (!force && (loginStatisticsDate !== null || statisticsUpdateState > 0)) return;
        setStatisticsUpdateState(1);
        console.log(`Update statistics force=${force}`);
        var collectStartDate = new Date();
        collectStartDate.setFullYear(collectStartDate.getFullYear() - 1);

        async function collectLogins(collectionName, setWeekData, setMonthData) {
            // get logins from last year only
            console.log("collect logins", collectionName)
            const loginsCollectionRef = collection(db, `statistics/${collectionName}/perDay`);
            const loginsQuery = query(loginsCollectionRef, where("timestamp", ">=", collectStartDate));
            // collect login statistics
            await getDocs(loginsQuery)
                .then(async (querySnapshot) => {
                    let loginsRaw = querySnapshot.docs.map((loginDoc) => ({
                        id: loginDoc.id,
                        ...loginDoc.data()
                    }));
                    var loginsPerWeek = Array(52).fill(0);
                    var loginsPerMonth = Array(12).fill(0);
                    loginsRaw.forEach(loginData => {
                        var loginDate = loginData.timestamp.toDate();
                        var week = Math.floor((loginDate - collectStartDate) / (7 * 24 * 60 * 60 * 1000)) - 1;
                        week = clamp(week, 0, 51);
                        loginsPerWeek[week] += loginData.logins;
                        var month = monthDiff(collectStartDate, loginDate) - 1;
                        month = clamp(month, 0, 11);
                        loginsPerMonth[month] += loginData.logins;
                    })
                    setWeekData(loginsPerWeek);
                    setMonthData(loginsPerMonth);
                }).catch((e) => {
                    setNotification(new Notification("error", "Update statistics", "Error getting login statistics: " + e));
                });
        }

        await collectLogins("logins", setLoginsPerWeek, setLoginsPerMonth);
        await collectLogins("service_logins", setServiceLoginsPerWeek, setServiceLoginsPerMonth);

        // get signups from last year only
        const signupsCollectionRef = collection(db, 'statistics/signups/perDay');
        const signupsQuery = query(signupsCollectionRef, where("timestamp", ">=", collectStartDate));
        // run update on statistics
        await getDocs(signupsQuery)
            .then(async (querySnapshot) => {
                let signupsRaw = querySnapshot.docs.map((signupDoc) => ({
                    id: signupDoc.id,
                    ...signupDoc.data()
                }));
                var signupsPerWeek = Array(52).fill(0);
                var signupsPerMonth = Array(12).fill(0);
                var userCountPerWeek = Array(52).fill(0);
                var userCountPerMonth = Array(12).fill(0);
                signupsRaw.forEach(signupData => {
                    var signupDate = signupData.timestamp.toDate();

                    var week = Math.floor((signupDate - collectStartDate) / (7 * 24 * 60 * 60 * 1000)) - 1;
                    week = clamp(week, 0, 51);
                    signupsPerWeek[week] += signupData.signups;

                    var month = monthDiff(collectStartDate, signupDate) - 1;
                    month = clamp(month, 0, 11);
                    signupsPerMonth[month] += signupData.signups;
                    // last entry per week/month is the user count
                    userCountPerWeek[week] = signupData.user_count;
                    userCountPerMonth[month] = signupData.user_count;
                })
                // fill in user count gaps
                var lastUserCount = 0;
                userCountPerMonth.forEach((value, index) => {
                    if (value > 0) {
                        lastUserCount = value;
                    } else {
                        userCountPerMonth[index] = lastUserCount;
                    }
                })
                lastUserCount = 0;
                userCountPerWeek.forEach((value, index) => {
                    if (value > 0) {
                        lastUserCount = value;
                    } else {
                        userCountPerWeek[index] = lastUserCount;
                    }
                })
                // set states
                setSignupsPerWeek(signupsPerWeek);
                setSignupsPerMonth(signupsPerMonth);
                setUserCountPerWeek(userCountPerWeek);
                setUserCountPerMonth(userCountPerMonth);
                setStatisticsUpdateState(2);
            }).catch((e) => {
                setNotification(new Notification("error", "Update statistics", "Error getting signup statistics: " + e));
            });

        // get last update dates
        const lastUpdateCollectionRef = collection(db, 'statistics');
        await getDocs(lastUpdateCollectionRef)
            .then(async (querySnapshot) => {
                let statsRaw = querySnapshot.docs.map((statsDoc) => ({
                    id: statsDoc.id,
                    ...statsDoc.data()
                }));
                statsRaw.forEach(statsData => {
                    const lastLogin = statsData.updated_until.toDate();
                    if (statsData.id === "logins") {
                        setLoginStatisticsDate(lastLogin);
                    } else if (statsData.id === "service_logins") {
                        setServiceLoginStatisticsDate(lastLogin);
                    } else if (statsData.id === "signups") {
                        setSignupStatisticsDate(lastLogin);
                    } else {
                        setNotification(new Notification("error", "Update statistics", "Error with statistics: " + statsData.id + " is unknown."));
                    }

                })
            }).catch((e) => {
                setNotification(new Notification("error", "Update statistics", "Error getting statistics update dates: " + e));
            });
    }

    return (
        <dataContext.Provider
            value={{
                users, refreshUsers, userLogins, fetchUserLogins, userStages, fetchUserStages, addUserStage, removeUserStage, saveUser, updateTrialPeriod,
                releases, refreshReleases, createRelease, saveRelease, deleteRelease,
                stages, refreshStages, saveStage,
                updateStatistics, collectLogins, collectSignups,
                loginStatisticsDate, loginsPerWeek, loginsPerMonth,
                serviceLoginStatisticsDate, serviceLoginsPerWeek, serviceLoginsPerMonth,
                signupStatisticsDate, signupsPerWeek, signupsPerMonth,
                userCountPerWeek, userCountPerMonth
            }}
        >
            {children}
        </dataContext.Provider>
    );
}

export function useDataContext() {
    return useContext(dataContext);
}
