import { useEffect, useState } from "react";
import { useDataContext } from '../context/DataContext.js'
import { useTheme } from '../context/ThemeContext.js'
import { useUserAuth } from "../context/UserAuthContext";
import { useParams, Link } from 'react-router-dom'
import { useNavigate } from "react-router";
import { Icon } from '@mdi/react';
import DatePicker from "react-datepicker";
import { mdiCloudCheckVariantOutline, mdiCloudLockOutline, mdiMicrosoftWindows, mdiApple, mdiAlertOutline, mdiPencil, mdiArrowLeftCircleOutline, mdiDeleteOutline } from '@mdi/js';

import "react-datepicker/dist/react-datepicker.css";

export default function Release({ children }) {
    const { releases, refreshReleases, saveRelease, deleteRelease } = useDataContext();
    const { themeColors } = useTheme();
    const [theRelease, setTheRelease] = useState({});
    const { authUserData } = useUserAuth();

    const [isActive, setIsActive] = useState(false);
    const [comment, setComment] = useState("");
    const [releaseDate, setReleaseDate] = useState(new Date());
    const [expirationDate, setExpirationDate] = useState(new Date());

    const { id, mode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        refreshReleases();
    });

    useEffect(() => {
        const updateRelease = async () => {

            const filteredRelease = releases.filter(
                (release) => release.id === id
            );
            if (filteredRelease.length !== 1) {
                setTheRelease({})
                return;
            }
            const release = filteredRelease[0];
            setIsActive(release.active);
            setReleaseDate(release.date);
            setExpirationDate(release.expirationDate);
            setComment(release.comment);
            setTheRelease(release);
        }

        updateRelease();
    }, [releases, id]);

    if (mode === "view") {
        return viewMode();
    } else if (mode === "edit") {
        return editMode();
    } else if (mode === "delete") {
        return deleteMode();
    }

    function viewMode() {
        return (
            <>
                <h1 className="title is-2">Release {theRelease?.fullVersion}</h1>
                <div className="fixed-grid">
                    <div className="grid">
                        <div className="cell">Public</div>
                        <div className="cell">{
                            theRelease.active ?
                                <Icon path={mdiCloudCheckVariantOutline} size={1} className="mr-2" color={themeColors.green1} title="Public release" />
                                : <Icon path={mdiCloudLockOutline} size={1} className="mr-2" color="red" title="Internal release only" />
                        }</div>
                        <div className="cell">Date</div>
                        <div className="cell">{theRelease?.dateString}</div>
                        <div className="cell">Expiration</div>
                        <div className="cell">{theRelease?.expirationDateString}</div>
                        <div className="cell">Installer(s)</div>
                        <div className="cell">
                            <label className="label">Nimagna {(!theRelease.configuration?.nimagna) && <>: not available</>}</label>
                            {(theRelease?.os?.windows && theRelease.configuration?.nimagna) && <><a className="button is-link is-outlined mr-2 mb-2" href={theRelease.url("windows", "nimagna")}><Icon path={mdiMicrosoftWindows} size={1} className="mr-2" /> Windows</a></>}
                            {(theRelease?.os?.mac_arm && theRelease.configuration?.nimagna) && <><a className="button is-link is-outlined mr-2" href={theRelease.url("mac_arm", "nimagna")}><Icon path={mdiApple} size={1} className="mr-2" /> macOS arm64</a></>}
                            {(theRelease?.os?.mac_intel && theRelease.configuration?.nimagna) && <><a className="button is-outlined is-link" href={theRelease.url("mac_intel", "nimagna")}><Icon path={mdiApple} size={1} className="mr-2" />macOS x86</a></>}
                            <label className="label">peoplefone STUDIO {(!theRelease.configuration?.peoplefone) && <>: n/a</>}</label>
                            {(theRelease?.os?.windows && theRelease.configuration?.peoplefone) && <><a className="button is-link is-outlined mr-2 mb-2" href={theRelease.url("windows", "peoplefone")}><Icon path={mdiMicrosoftWindows} size={1} className="mr-2" /> Windows</a></>}
                            {(theRelease?.os?.mac_arm && theRelease.configuration?.peoplefone) && <><a className="button is-link is-outlined mr-2" href={theRelease.url("mac_arm", "peoplefone")}><Icon path={mdiApple} size={1} className="mr-2" /> macOS arm64</a></>}
                            {(theRelease?.os?.mac_intel && theRelease.configuration?.peoplefone) && <><a className="button is-outlined is-link" href={theRelease.url("mac_intel", "peoplefone")}><Icon path={mdiApple} size={1} className="mr-2" />macOS x86</a></>}
                        </div>
                        <div className="cell">Comment</div>
                        <div className="cell">{theRelease?.comment}</div>
                    </div>
                </div>
                <div className="buttons is-right">
                    <button
                        className="button is-primary"
                        onClick={() => navigate(-1)}>
                        <Icon path={mdiArrowLeftCircleOutline} size={1} className="mr-2" title="Back" />Back
                    </button>
                    {
                        authUserData?.isAdmin && <Link className="button is-link is-outlined" to={`/release/${theRelease?.id}/edit`}>Edit<Icon path={mdiPencil} size={1} className="ml-2" title="Edit release" /></Link>
                    }
                </div>
            </>
        );
    }

    function editMode() {
        return (
            <>
                <h1 className="title is-2">Edit Release {theRelease?.fullVersion}</h1>

                <div className="field">
                    <label className="label">Public</label>
                    <div className="control">
                        <button className={isActive ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setIsActive(!isActive); }}>
                            <Icon path={isActive ? mdiCloudCheckVariantOutline : mdiCloudLockOutline} size={1} className="mr-2" /> {
                                isActive ? "Public" : "Internal"
                            }
                        </button>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Date</label>
                    <div className="control">
                        <DatePicker selected={releaseDate} onChange={(date) => setReleaseDate(date)} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Expiration Date</label>
                    <div className="control">
                        <DatePicker selected={expirationDate} onChange={(date) => setExpirationDate(date)} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Comment</label>
                    <div className="control">
                        <input className="input" type="text" value={comment} onChange={(e) => setComment(e.target.value)} />
                    </div>
                </div>

                <div className="buttons is-right">
                    <button
                        className="button is-primary"
                        onClick={(e) => {
                            saveRelease(theRelease.id, isActive, releaseDate, expirationDate, comment);
                            navigate("/release/" + theRelease.id + "/view");
                        }}>
                        Save
                    </button>
                    <button className="button is-link is-outlined" onClick={() => navigate(-1)}>Cancel</button>
                </div>
            </>
        );
    }

    function deleteMode() {
        return (
            <>
                <h1 className="title is-2">Release {theRelease?.fullVersion}</h1>

                <div className="notification is-danger">
                    <div className="level-left">
                        <div className="level-item">
                            <Icon path={mdiAlertOutline} size={3} color="red" className="" title="Delete release" />
                        </div>
                        <div className="level-item">
                            Do you really want to delete the release {theRelease?.fullVersion}?<br />
                            This is a irrevocable action.
                        </div>
                    </div>
                </div>

                <div className="buttons is-right">
                    <button
                        className="button is-primary"
                        onClick={(e) => {
                            deleteRelease(theRelease.id);
                            navigate("/releases/list");
                        }}>
                        <Icon path={mdiDeleteOutline} size={1} title="Delete release" className="mr-1" />Delete
                    </button>
                    <button className="button is-link is-outlined" onClick={() => navigate(-1)}>Cancel</button>
                </div>
            </>
        );
    }


}