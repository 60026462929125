import { collection, getDocs, query, where, getDoc, setDoc, doc, limit } from 'firebase/firestore';
import { db } from '../firebase-config';
import { orderBy } from 'firebase/firestore/lite';

// collect various statistics into the statistics collection
// each statistic should handle a date in the general document to prevent from processing all data all over again

async function getCollectionStartDate(type) {
    // get type's statistics document
    let docSnap = await getDoc(doc(db, 'statistics', type));
    if (docSnap.exists()) {
        const docData = docSnap.data();
        return docData.updated_until.toDate();
    } else {
        return new Date("2020-01-01");
    }
}


export async function collectUserLogins() {
    await collectUserLoginsPerApp("Nimagna App", "logins");
    await collectUserLoginsPerApp("Nimagna Service", "service_logins");
}

async function collectUserLoginsPerApp(appFilter, collectionTarget) {
    var collectStartDate = await getCollectionStartDate(collectionTarget);
    // collect until start of today
    var collectEndDate = new Date();
    collectEndDate.setHours(0);
    collectEndDate.setMinutes(0);
    collectEndDate.setSeconds(0);
    collectEndDate.setMilliseconds(0);

    // get users collection (except developers)
    const usersCollectionRef = collection(db, 'users');
    const usersQuery = query(usersCollectionRef, where("userType", "!=", 100));
    var loginsPerDay = new Map();
    await getDocs(usersQuery)
        .then(async (querySnapshot) => {
            // for each user
            for (const userDocument of querySnapshot.docs) {
                // fetch all logins mathing the appFilter after collectionDate
                console.log("Collect", userDocument.id, "...");
                const logsCollectionRef = collection(db, `users/${userDocument.id}/logins`);
                const logsq = query(logsCollectionRef, where("timestamp", ">=", collectStartDate), where("application", "==", appFilter));
                await getDocs(logsq)
                    .then(async (querySnapshot) => {
                        // for each login
                        console.log("...", querySnapshot.docs.length, collectionTarget);
                        for (const loginDocument of querySnapshot.docs) {
                            const loginData = loginDocument.data();
                            const loginTimestamp = loginData.timestamp.toDate();
                            const loginDay = loginTimestamp.toISOString().split('T')[0];
                            if (loginsPerDay.has(loginDay)) {
                                loginsPerDay.set(loginDay, loginsPerDay.get(loginDay) + 1);
                            } else {
                                loginsPerDay.set(loginDay, 1);
                            }
                        }
                    }).catch((e) => {
                        console.error("Error getting users: ", e);
                    });
            }
            if (loginsPerDay.size > 0) {
                console.log("Collected", loginsPerDay.size, collectionTarget, " from", collectStartDate, " to ", collectEndDate);
                for (const [key, value] of loginsPerDay) {
                    console.log("Write", key, ":", value);
                    await setDoc(doc(db, `statistics`, collectionTarget + "/perDay/" + key), {
                        logins: value,
                        timestamp: new Date(key)
                    }).catch((e) => {
                        console.error(`Error writing ${collectionTarget} data: `, e);
                    });
                }
            } else {
                console.log(`No (new) ${collectionTarget} from`, collectStartDate, " to ", collectEndDate);
            }

            // update collection end timestamp
            await setDoc(doc(db, `statistics`, collectionTarget), {
                updated_until: collectEndDate,
            }).catch((e) => {
                console.error("Error login setting collection end date: ", e);
            });
        }).catch((e) => {
            console.error("Error getting users: ", e);
        });
}

export async function collectUserSignups(generalDoc) {
    var collectStartDate = await getCollectionStartDate("signups");
    // collect until start of today
    var collectEndDate = new Date();
    collectEndDate.setHours(0);
    collectEndDate.setMinutes(0);
    collectEndDate.setSeconds(0);
    collectEndDate.setMilliseconds(0);

    // get users collection with created date after collect start date
    var signupsPerDay = new Map();
    const usersCollectionRef = collection(db, 'users');
    const usersQuery = query(usersCollectionRef, where("userCreatedDate", ">=", collectStartDate));
    await getDocs(usersQuery)
        .then(async (querySnapshot) => {
            // for each user
            for (const userDocument of querySnapshot.docs) {
                const userData = userDocument.data();
                console.log("Collect", userDocument.id, "...");
                const signupTimestamp = userData.userCreatedDate.toDate();
                const signupDay = signupTimestamp.toISOString().split('T')[0];
                if (signupsPerDay.has(signupDay)) {
                    signupsPerDay.set(signupDay, signupsPerDay.get(signupDay) + 1);
                } else {
                    signupsPerDay.set(signupDay, 1);
                }
            }
        }).catch((e) => {
            console.error("Error getting users: ", e);
        });
    if (signupsPerDay.size > 0) {
        console.log("collect", signupsPerDay.size, "signups from", collectStartDate, " to ", collectEndDate);
    }
    var userCount = 0;
    // try to find last user count
    const signupsCollectionRef = collection(db, 'statistics/signups/perDay');
    const lastSignupDataQuery = query(signupsCollectionRef, where("timestamp", "<", collectStartDate), orderBy("timestamp", "desc"), limit(1));
    await getDocs(lastSignupDataQuery)
        .then((lastSignupQuerySnapshot) => {
            if (lastSignupQuerySnapshot.docs.length === 1) {
                // found the latest existing data entry
                const lastSignupData = lastSignupQuerySnapshot.docs[0].data();
                userCount = lastSignupData.user_count;
            }
        }).catch((e) => {
            console.error("Error getting last signup data point: ", e);
        });

    // write data points
    for (const [key, value] of signupsPerDay) {
        userCount += value;
        await setDoc(doc(db, `statistics`, "signups/perDay/" + key), {
            signups: value,
            user_count: userCount,
            timestamp: new Date(key)
        }).catch((e) => {
            console.error("Error writing signup data: ", e);
        });
    }

    // update collection end timestamp
    await setDoc(doc(db, `statistics`, "signups"), {
        updated_until: collectEndDate,
    }).catch((e) => {
        console.error("Error setting signup collection end date: ", e);
    });
}

