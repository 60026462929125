import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import 'bulma/css/bulma.css';
import "./admin-app.css";
import "./App.css";
import { NotificationContextProvider, useNotificationContext } from "./context/NotificationContext";
import { DataContextProvider } from "./context/DataContext";
import { ThemeContextProvider } from "./context/ThemeContext";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { Navigation } from "./components/Navigation";
import ProtectedRoute from "./components/ProtectedRoute"
import { Year } from "./components/Helpers";
import Home from "./components/Home";
import Login from "./components/Login";
import Users from "./components/Users";
import User from "./components/User";
import Releases from "./components/Releases";
import Release from "./components/Release";
import Stages from "./components/Stages";
import Stage from "./components/Stage";
import Statistics from "./components/Statistics";

/**
 * The main App creates the routing to show different elements based on the URL.
 * The main layout is defined in Layout() at the bottom.
 */

const router = createBrowserRouter([
    {
        // the general page layout
        element: <Layout />,
        children: [
            { path: "/login", element: <Login /> },
            {
                element:
                    <ProtectedRoute>
                        <DataContextProvider>
                            <Outlet />
                        </DataContextProvider>
                    </ProtectedRoute>,
                children: [
                    { path: "/home/:mode", element: <Home /> },
                    { path: "/users", element: <Users /> },
                    { path: "/user/:id/:mode", element: <User /> },
                    { path: "/releases/:mode", element: <Releases /> },
                    { path: "/release/:id/:mode", element: <Release /> },
                    { path: "/stages", element: <Stages /> },
                    { path: "/stage/:id/:mode", element: <Stage /> },
                    { path: "/statistics", element: <Statistics /> },
                ]
            },
            { path: "*", element: <Login /> },
        ]
    },
]);
export default function App() {
    return (
        <>
            <NotificationContextProvider>
                <UserAuthContextProvider>
                    <ThemeContextProvider>
                        <RouterProvider router={router} />
                    </ThemeContextProvider>
                </UserAuthContextProvider>
            </NotificationContextProvider>
        </>
    );
}
function Layout() {

    const { notification, hideNotification } = useNotificationContext();
    const [ notificationShown, setShowingNotification] = useState(false);

    // timeout to make notifications disappear
    useEffect(() => {
        if (notification) {
            if (notification.type === "error") {
                setTimeout(hideNotification, 15000);
            }
            if (notification.type === "information") {
                setTimeout(hideNotification, 5500);
            }
            setShowingNotification(true);
        }
    }, [notification, hideNotification]);

    function capitalizeFirstLetter(string) {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    return (
        <>
            <Navigation />
            <div className="columns is-centered">
                <div className="column is-two-thirds">
                    <div className="block m-5">
                        {(notification) &&
                            <>
                                <div 
                                    className={notification.type === "error" ? "notification is-danger " + (notificationShown ? 'fade-in' : 'fade-out-10') : "notification is-info is-light " + (notificationShown ? 'fade-in' : 'fade-out-5')  }
                                    onTransitionEnd={() => { setShowingNotification(false); }}
                                >
                                    <button className="delete" onClick={hideNotification} />
                                    <h1 className="title is-4">{capitalizeFirstLetter(notification.type)}: {notification.title}</h1>
                                    <div className="block">{notification.description}</div>
                                </div>
                            </>
                        }
                        <Outlet />
                    </div>
                </div>
            </div>
            <footer className="footer has-text-centered">
                This is the Nimagna Admin center. Access is restricted.<br />
                Visit <a href="https://www.nimagna.com">nimagna.com</a> for more information about Nimagna.<br />
                &copy; <Year /> by Nimagna AG. All rights reserved.
            </footer>
        </>
    );
}
