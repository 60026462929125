import { useEffect, useState } from "react";
import { useDataContext } from '../context/DataContext.js'
import { useUserAuth } from "../context/UserAuthContext";
import { useTheme } from "../context/ThemeContext";
import { useParams, Link } from 'react-router-dom'
import { useNavigate } from "react-router";
import { Icon } from '@mdi/react';
import { userLevels } from "../components/Helpers"
import DatePicker from "react-datepicker";
import { mdiCheckCircleOutline, mdiAlertCircleOutline, mdiEmailNewsletter, mdiMicrosoftWindows, mdiApple, mdiCloudUploadOutline } from '@mdi/js';

import "react-datepicker/dist/react-datepicker.css";

export default function User({ children }) {
    const { users, refreshUsers, userLogins, fetchUserLogins, userStages, fetchUserStages, stages, refreshStages, addUserStage, removeUserStage, saveUser, updateTrialPeriod } = useDataContext();
    const { authUserData } = useUserAuth();
    const { themeColors } = useTheme();

    const [theUser, setTheUser] = useState({});
    const [loginsLastMonth, setLoginsLastMonth] = useState(0);
    const [loginsLastSixMonths, setLoginsLastSixMonths] = useState(0);
    const [nonUserStages, setNonUserStages] = useState([]);
    const [trialExtension, setTrialExtension] = useState(2);

    const { id, mode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        refreshUsers();
        refreshStages();
    });

    useEffect(() => {
        const updateUser = async () => {
            const filteredUser = users.filter(
                (user) => user.id === id
            );
            if (filteredUser.length !== 1) {
                setTheUser({})
                return;
            }
            const user = filteredUser[0];
            fetchUserLogins(user.id);
            fetchUserStages(user.id);
            setTheUser(user);
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setCreatedDate(user.createdDate);
            setEndOfTrialPeriodDate(user.endOfTrialPeriod);
            setUserLevel(user.userType);
            setNewsletterFlag(user.hasNewsletter);
            setAllowAsposeFlag(user.allowAspose);
        }

        updateUser();
    }, [users, id, fetchUserLogins, fetchUserStages]);

    useEffect(() => {
        const analyseUserDetails = async () => {
            const today = new Date();
            const dayLength = 24 * 60 * 60 * 1000;
            const lastMonthLogins = userLogins.filter((login) => {
                return today - login.timestamp.toDate() <= 30 * dayLength;
            })
            setLoginsLastMonth(lastMonthLogins.length);
            const lastSixMonthsLogins = userLogins.filter((login) => {
                return today - login.timestamp.toDate() <= 365 / 2 * dayLength;
            })
            setLoginsLastSixMonths(lastSixMonthsLogins.length);
        }
        analyseUserDetails();
    }, [userLogins, theUser]);

    useEffect(() => {
        var filteredStages = [];
        stages.forEach(stage => {
            if (!stage.public) {
                const filteredUserStages = userStages.filter(userStage => {
                    return (userStage.id === stage.id);
                });
                if (filteredUserStages.length === 0) {
                    filteredStages.push(stage);
                }

            }
        })
        setNonUserStages(filteredStages);
    }, [stages, userStages])

    const trialExtensionOptions = 
    [
        {
            "option": 0,
            "type": "Reset",
            "value": 0,
        },
        {
            "option": 1,
            "type": "by",
            "value": 7,
        },
        {
            "option": 2,
            "type": "by",
            "value": 15,
        },
        {
            "option": 3,
            "type": "by",
            "value": 30,
        },
        {
            "option": 4,
            "type": "in",
            "value": 7,
        },
        {
            "option": 5,
            "type": "in",
            "value": 15,
        },
        {
            "option": 6,
            "type": "in",
            "value": 30,
        }
    ];
    function performTrialPeriodUpdate() {
        var selectedOption = trialExtensionOptions.find((option) => {return option.option===trialExtension;})
        console.log(selectedOption);
        if (selectedOption?.type==="by") {
            // extend trial period by some days
            var newTrialPeriodEndBy = new Date(theUser.endOfTrialPeriod);
            newTrialPeriodEndBy.setDate(newTrialPeriodEndBy.getDate() + selectedOption.value);
            updateTrialPeriod(theUser.id, newTrialPeriodEndBy);
        } else if (selectedOption?.type==="in") {
            // set trial period to now + some days
            var newTrialPeriodEndIn = new Date();
            newTrialPeriodEndIn.setDate(newTrialPeriodEndIn.getDate()+selectedOption.value);
            updateTrialPeriod(theUser.id, newTrialPeriodEndIn);
        } else if (selectedOption?.type==="Reset") {
            // set trial period to creation date + 1 month
            var newTrialPeriodEndReset = new Date(theUser.createdDate);
            newTrialPeriodEndReset.setMonth(newTrialPeriodEndReset.getMonth() + 1);
            updateTrialPeriod(theUser.id, newTrialPeriodEndReset);
        }
    }

    function viewMode() {
        return (
            <>
                <h1 className="title is-2">{theUser?.displayName}</h1>
                <div className="fixed-grid">
                    <div className="grid">
                        <div className="cell">Email</div>
                        <div className="cell">{theUser?.emailAddress} {theUser && (
                            theUser.emailVerified ?
                                <Icon path={mdiCheckCircleOutline} size={1} className="mr-2" color={themeColors.green1} title="Email verified" />
                                : <Icon path={mdiAlertCircleOutline} size={1} className="mr-2" color="orange" title="Email not verified" />)
                        }</div>
                        <div className="cell">ID</div>
                        <div className="cell">{theUser?.id}</div>
                        <div className="cell">Created</div>
                        <div className="cell">{theUser?.createdDateString}</div>
                        <div className="cell">End of trial period</div>
                        <div className="cell">{theUser?.endOfTrialPeriodString}</div>
                        <div className="cell">Level</div>
                        <div className="cell">{theUser?.userTypeString}</div>
                        <div className="cell">Newsletter</div>
                        <div className="cell">{
                            theUser?.hasNewsletter ?
                                <Icon path={mdiEmailNewsletter} size={1} className="mr-2" color={themeColors.green1} title="Has newsletter" />
                                : <Icon path={mdiEmailNewsletter} size={1} className="mr-2" color="red" title="Has no newsletter" />
                        }</div>
                        <div className="cell">Aspose</div>
                        <div className="cell">{
                            theUser?.allowAspose ?
                                <Icon path={mdiCloudUploadOutline} size={1} className="mr-2" color={themeColors.green1} title="Enabled" />
                                : <Icon path={mdiCloudUploadOutline} size={1} className="mr-2" color="red" title="Disabled" />
                        }</div>
                        <div className="cell">Operating system(s)</div>
                        <div className="cell">
                            {theUser?.usingWindows ? <Icon path={mdiMicrosoftWindows} size={1} className="mr-2" title="Windows" /> : ""}
                            {theUser?.usingMacOS ? <Icon path={mdiApple} size={1} className="mr-2" title="macOS" /> : ""}
                        </div>
                        <div className="cell">User Class</div>
                        <div className="cell">{theUser?.userClassTag}</div>
                        <div className="cell">New score</div>
                        <div className="cell">{theUser?.newUserClassTag} ({theUser?.newUserClassValue?.toFixed(2)})</div>
                        <div className="cell">Last used version</div>
                        <div className="cell">{theUser?.lastVersion}</div>
                    </div>
                </div>
                {userLogins?.length > 0 &&
                    <>
                        <h1 className="title is-3">Logins</h1>
                        <div className="fixed-grid">
                            <div className="grid">
                                <div className="cell">Last login</div>
                                <div className="cell">{theUser?.lastLoginString} </div>
                                <div className="cell">Logins last month</div>
                                <div className="cell">{loginsLastMonth} </div>
                                <div className="cell">Logins last six months</div>
                                <div className="cell">{loginsLastSixMonths} </div>
                                <div className="cell">Logins total</div>
                                <div className="cell">{theUser?.loginCount}</div>
                                <div className="cell">Last Service login</div>
                                <div className="cell">{theUser?.lastServiceLoginString} </div>
                            </div>
                        </div>
                        {theUser.userType === 100 && <div className="block has-text-warning">Note: No login data for developer users since Version 24.2!</div>}
                    </>
                }
                <h1 className="title is-3">Private Stages</h1>
                {userStages?.length === 0 && <div className="block">{theUser.displayName} has no private stages assigned.</div>}
                <div className="block">
                    {userStages.map(stage => {
                        return <div key={stage.id} className="tag mr-3 is-large">{stage.id}
                            {
                                authUserData?.isAdmin && <button className="delete ml-2" id={stage.id} onClick={(e) => { removeUserStage(theUser.id, e.target.id); }}></button>
                            }
                        </div>;
                    })}
                    {
                        authUserData?.isAdmin && nonUserStages.length > 0 && <div className="select">
                            <select onChange={(e) => {
                                if (e.target.value !== "Undefined") {
                                    addUserStage(theUser.id, e.target.value);
                                }
                            }}>
                                <option key="Undefined">Add ...</option>
                                {nonUserStages.map(stage => {
                                    return <option key={stage.id}>{stage.id}</option>;
                                })}
                            </select>
                        </div>
                    }
                </div>
                {
                    (authUserData?.isAdmin && (theUser?.userTypeString === "Trial" || theUser?.userTypeString === "Free")) &&
                    <>
                        <h1 className="title is-3">Adapt trial period</h1>
                        <div className="buttons">
                            <div className="control">
                                <div className="select">
                                    <select name="trialExtension" value={trialExtension} onChange={(e) => setTrialExtension(parseInt(e.target.value))}>
                                        {
                                            trialExtensionOptions.map(option => {
                                                var optionText = "" + option.type + " " + (option.option!==0 ? (option.value + " days") : "");
                                                return <option value={option.option} key={option.option}>{optionText}</option>;
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            <button
                                className="button is-link is-outlined"
                                onClick={(e) => {performTrialPeriodUpdate()}}>
                                Update trial period
                            </button>
                        </div>
                    </>
                }
                <div className="buttons is-right">
                    {
                        authUserData?.isAdmin && <Link className="button is-link is-outlined" to={`/user/${id}/edit`}>Edit</Link>
                    }
                    <button
                        className="button is-primary"
                        onClick={() => navigate(-1)}>
                        Back
                    </button>
                </div>
            </>
        );
    }

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [createdDate, setCreatedDate] = useState(new Date());
    const [endOfTrialPeriodDate, setEndOfTrialPeriodDate] = useState(new Date());
    const [userLevel, setUserLevel] = useState(0);
    const [newsletterFlag, setNewsletterFlag] = useState(false);
    const [allowAsposeFlag, setAllowAsposeFlag] = useState(false);

    function editMode() {
        return (
            <>
                <h1 className="title is-2">Edit {theUser?.displayName}</h1>

                <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                        {theUser?.emailAddress} {theUser && (
                            theUser.emailVerified ?
                                <Icon path={mdiCheckCircleOutline} size={1} className="mr-2" color={themeColors.green1} title="Email verified" />
                                : <Icon path={mdiAlertCircleOutline} size={1} className="mr-2" color="orange" title="Email not verified" />)
                        }
                    </div>
                </div>

                <div className="field">
                    <label className="label">First name</label>
                    <div className="control">
                        <input name="first" className="input" type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Last name</label>
                    <div className="control">
                        <input name="last" className="input" type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">Created</label>
                    <div className="control">
                        <DatePicker selected={createdDate} onChange={(date) => setCreatedDate(date)} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">End of Trial Period</label>
                    <div className="control">
                        <DatePicker selected={endOfTrialPeriodDate} onChange={(date) => setEndOfTrialPeriodDate(date)} />
                    </div>
                </div>

                <div className="field">
                    <label className="label">User Level</label>
                    <div className="control">
                        <div className="select">
                            <select name="userLevel" value={userLevel} onChange={(e) => setUserLevel(e.target.value)}>
                                {
                                    userLevels().map(level => {
                                        return <option value={level.value} key={level.value}>{level.name}</option>;
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Newsletter</label>
                    <div className="control">
                        <button className={newsletterFlag ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setNewsletterFlag(!newsletterFlag); }}>
                            <Icon path={mdiEmailNewsletter} size={1} className="mr-2" /> {
                                newsletterFlag ? "Subscribed" : "Not subscribed"
                            }
                        </button>
                    </div>
                </div>

                <div className="field">
                    <label className="label">Aspose</label>
                    <div className="control">
                        <button className={allowAsposeFlag ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setAllowAsposeFlag(!allowAsposeFlag); }}>
                            <Icon path={mdiCloudUploadOutline} size={1} className="mr-2" /> {
                                allowAsposeFlag ? "Aspose enabled" : "Aspose disabled"
                            }
                        </button>
                    </div>
                </div>

                <div className="buttons is-right">
                    <button
                        className="button is-primary"
                        onClick={(e) => {
                            saveUser(theUser.id, firstName, lastName, createdDate, endOfTrialPeriodDate, parseInt(userLevel), newsletterFlag, allowAsposeFlag);
                            navigate("/user/" + theUser.id + "/view");
                        }}>
                        Save
                    </button>
                    <button className="button is-link is-outlined" onClick={() => navigate(-1)}>Cancel</button>
                </div>
            </>
        );
    }

    if (mode === "view") {
        return viewMode();
    } else if (mode === "edit") {
        return editMode();
    }
}