import { createContext, useContext, useEffect, useState } from "react";

/**
 * The ThemeContext provides information about the current theme
 */

const themeContext = createContext();

export function ThemeContextProvider({ children }) {

    const [theme, setTheme] = useState(localStorage.getItem('theme') ? localStorage.getItem('theme') : "system");
    const [osTheme, setOsTheme] = useState(null);
    const [themeColors, setThemeColors] = useState({});
    const DEFAULT_THEME = "light";

    function toggleTheme() {
        var currentTheme = theme;
        if (theme === "system") {
            currentTheme = osTheme;
        }
        if (currentTheme === "light") {
            setTheme("dark");
        } else {
            setTheme("light");
        }
    }

    const detectOSTheme = () => {
        if (!window.matchMedia) {
            // matchMedia method not supported
            setOsTheme(DEFAULT_THEME);
        }

        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
            // OS theme setting detected as dark
            setOsTheme("dark");
        } else if (window.matchMedia("(prefers-color-scheme: light)").matches) {
            setOsTheme("light");
        } else {
            setOsTheme(DEFAULT_THEME);
        }
    };

    useEffect(() => {
        localStorage.setItem('theme', theme);
        if (theme === "light") {
            setThemeColors({
                "green1": "Green",      // primary green
                "green2": "LimeGreen",  // secondary green
            });
        } else {
            setThemeColors({
                "green1": "LimeGreen",
                "green2": "Green",
            });
        }
    }, [theme]);

    useEffect(() => {
        // eslint-disable-next-line no-restricted-globals
        detectOSTheme();
    });

    useEffect(() => {
        if (theme === "system") {
            document.documentElement.setAttribute("data-theme", osTheme);
        } else {
            document.documentElement.setAttribute("data-theme", theme);
        }
    }, [theme, osTheme]);

    return (
        <themeContext.Provider
            value={{ theme, toggleTheme, themeColors }}
        >
            {children}
        </themeContext.Provider>
    );
}

export function useTheme() {
    return useContext(themeContext);
}
