import { useEffect, useMemo, useState } from "react";
import { useDataContext } from '../context/DataContext.js'
import { useUserAuth } from "../context/UserAuthContext";
import { useTheme } from "../context/ThemeContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Icon } from '@mdi/react';
import { mdiEmailNewsletter, mdiMicrosoftWindows, mdiApple, mdiSortAscending, mdiSortDescending, mdiPageNextOutline, mdiPagePreviousOutline, mdiEyeOutline, mdiPencil, mdiCheckCircleOutline, mdiAlertCircleOutline } from '@mdi/js';
import { getUserTypeString } from './Helpers.js'

/**
 * Displays an overview of the users.
 */

export default function Users({ children }) {
    const { users, refreshUsers } = useDataContext();
    const { authUserData } = useUserAuth();
    const { themeColors } = useTheme();

    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [sortBy, setSortBy] = useState("name");
    const [sortDirection, setSortDirection] = useState(true);
    const [filterValue, setFilterValue] = useState('');
    const [filterType, setFilterType] = useState(null);
    const [filterTypes, setFilterTypes] = useState([]);
    const navigate = useNavigate();

    // filtering the users
    const filteredUsers = useMemo(() => {
        return users.filter(user => {
            return (user.emailAddress?.toLowerCase().includes(filterValue)
                || user.firstName?.toLowerCase().includes(filterValue)
                || user.lastName?.toLowerCase().includes(filterValue))
                && (!filterType || filterType === user.userType);
        })
    }, [users, filterValue, filterType]);

    // sorting the users
    const sortedUsers = useMemo(() => {
        let sortedArray = filteredUsers.toSorted();
        if (sortBy === "name") {
            sortedArray.sort((l, r) => {
                // sort alphabetically by display name
                let x = l.displayName.toLowerCase();
                let y = r.displayName.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            });
        } else if (sortBy === "type") {
            sortedArray.sort((l, r) => {
                // sort numerically by user type
                return l.userType - r.userType;
            });
        } else if (sortBy === "created") {
            sortedArray.sort((l, r) => {
                // default: sorted from latest to oldest!
                return r.createdDate - l.createdDate;
            });
        } else if (sortBy === "login") {
            sortedArray.sort((l, r) => {
                // default: sorted from latest to oldest!
                return r.lastLogin - l.lastLogin;
            });
        }
        if (sortDirection === false) {
            sortedArray.reverse();
        }
        return sortedArray;
    }, [filteredUsers, sortBy, sortDirection]);

    // slicing the users into pages
    const slicedUsers = useMemo(() => {
        const newPageCount = Math.ceil(sortedUsers.length / pageSize);
        setPageCount(newPageCount);
        if (currentPage < 0) {
            setCurrentPage(0);
        }
        if (currentPage > newPageCount) {
            setCurrentPage(newPageCount - 1);
        }
        return sortedUsers.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
    }, [sortedUsers, pageSize, currentPage]);

    // the page access buttons content
    const pageButtons = useMemo(() => {
        const maxButtonCount = 3;
        const startIndex = Math.max(currentPage - Math.floor(maxButtonCount / 2), 0);
        const endIndex = Math.min(startIndex + maxButtonCount, pageCount);
        const length = endIndex - startIndex;
        return Array.from({ length: length }, (_, i) => i + startIndex);
    }, [currentPage, pageCount]);

    useEffect(() => {
        // collect all types and sort
        const unique = [...new Set(users.map(user => parseInt(user.userType)))];
        // sort by number value!
        let sortedArray = Array.from(unique).sort((a, b) => {
            return a - b;
        });
        setFilterTypes(sortedArray);
    }, [users]);

    useEffect(() => {
        refreshUsers();
    });

    function resetSortBy(type) {
        setSortDirection(true);
        setSortBy(type);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const sortTypes = ["name", "type", "created", "login"];
    const pageSizes = [5, 10, 20];

    return (
        <>
            <div className="section">
                <div className="block">
                    <h1 className="title is-1 has-text-centered">Users</h1>
                    <div className="block has-text-centered">All Nimagna users.</div>
                </div>
                <div className="block">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">
                                <div className="field">
                                    <div className="control">
                                        <input name="filter" className="input" type="text" placeholder="Search" onChange={(e) => { setFilterValue(e.target.value.toLowerCase()); }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="level-right">
                            <div className="level-item">
                                {
                                    filterTypes.map(buttonType => {
                                        var style = "button mr-1";
                                        if (buttonType === filterType) {
                                            style += " is-active is-primary";
                                        }
                                        return <button className={style} onClick={() => { setFilterType(filterType === buttonType ? null : buttonType); }} key={buttonType}>{getUserTypeString(buttonType)}</button>
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="block">
                    <table className="table is-hoverable is-fullwidth is-striped ">
                        <thead key="head">
                            <tr key="headtr">
                                {
                                    sortTypes.map(type => {
                                        return <th key={type} className={"is-clickable" + (sortBy === type ? " is-underlined" : "")} onClick={() => { sortBy === type ? setSortDirection(!sortDirection) : resetSortBy(type) }}>
                                            <div className="level">
                                                <div className="level-left">
                                                    <div className="level-item">{capitalizeFirstLetter(type)}</div>
                                                    {sortBy === type && <div className="level-item"><Icon path={sortDirection ? mdiSortAscending : mdiSortDescending} size={0.75} /></div>}
                                                </div>
                                            </div>
                                        </th>
                                    })
                                }
                                <th key="info">
                                    Info
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody key="body">
                            {
                                slicedUsers.map(user => {
                                    var typeTagClass = "tag is-info";
                                    switch (user.userTypeLevel) {
                                        case "Free":
                                        default:
                                            break;
                                        case "Paid":
                                            typeTagClass = "tag is-warning";
                                            break;
                                        case "Internal":
                                            typeTagClass = "tag is-danger";
                                            break;
                                    }
                                    return <tr key={user.id} id={user.id} >
                                        <td className="is-clickable" onClick={(e) => { navigate(`/user/${user.id}/view`); }}>{user.displayName}</td>
                                        <td className="is-clickable" onClick={(e) => { navigate(`/user/${user.id}/view`); }}><span className={typeTagClass}>{user.userTypeString}</span></td>
                                        <td className="is-clickable" onClick={(e) => { navigate(`/user/${user.id}/view`); }}>{user.createdDateString}</td>
                                        <td className="is-clickable" onClick={(e) => { navigate(`/user/${user.id}/view`); }}>{user.lastLoginString}</td>
                                        <td className="is-clickable" onClick={(e) => { navigate(`/user/${user.id}/view`); }}>
                                            {user.emailVerified ?
                                                <Icon path={mdiCheckCircleOutline} size={1} className="mr-2" color={themeColors.green1} title="Email verified" />
                                                : <Icon path={mdiAlertCircleOutline} size={1} className="mr-2" color="orange" title="Email not verified" />}
                                            {user.hasNewsletter ?
                                                <Icon path={mdiEmailNewsletter} size={1} className="mr-2" color={themeColors.green1} title="Has newsletter" />
                                                : <Icon path={mdiEmailNewsletter} size={1} className="mr-2" color="red" title="Has no newsletter" />}
                                            {user.userClassTag}
                                            {user.usingWindows ? <Icon path={mdiMicrosoftWindows} size={1} className="mr-2" title="Windows" /> : ""}
                                            {user.usingMacOS ? <Icon path={mdiApple} size={1} className="mr-2" title="macOS" /> : ""}
                                        </td>
                                        <td className="buttons is-right">
                                            <Link to={`/user/${user?.id}/view`} ><Icon path={mdiEyeOutline} size={1} title="View details" /></Link>
                                            {authUserData?.isAdmin && <Link to={`/user/${user?.id}/edit`} ><Icon path={mdiPencil} size={1} title="Edit release" /></Link>}
                                        </td>
                                    </tr>;
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="block">
                    <div className="level">
                        <div className="level-left">
                            <div className="level-item">Page:</div>
                            {
                                (currentPage > 0) &&
                                <div className="level-item"><button className="button mr-1" onClick={() => { setCurrentPage(currentPage - 1); }} ><Icon path={mdiPagePreviousOutline} size={1} title="Previous page" /></button></div>
                            }
                            {
                                (pageButtons[0] > 0) && <div className="level-item"><button className="button mr-1" onClick={() => { setCurrentPage(pageButtons[0] - 1); }} >{pageButtons[0] - 1 === 0 ? 1 : "..."}</button></div>
                            }
                            {
                                pageButtons.map(page => {
                                    const classValue = "button mr-1" + (page === currentPage ? " is-active is-primary" : "");
                                    return <div key={page} className="level-item"><button className={classValue} onClick={() => { setCurrentPage(page); }} key={page}>{page + 1}</button></div>
                                })
                            }
                            {
                                (pageButtons[pageButtons.length - 1] + 1 < pageCount) && <div className="level-item"><button className="button mr-1" onClick={() => { setCurrentPage(pageButtons[pageButtons.length - 1] + 1); }} >{pageButtons[pageButtons.length - 1] + 2 === pageCount ? pageCount : "..."}</button></div>
                            }
                            {
                                (currentPage < Math.ceil(sortedUsers.length / pageSize) - 1) && <div className="level-item"><button className="button mr-1" onClick={() => { setCurrentPage(currentPage + 1); }} ><Icon path={mdiPageNextOutline} size={1} title="Next page" /></button></div>
                            }
                        </div>
                        <div className="level-right">
                            <div className="level-item">Users per page:</div>
                            <div className="level-item">
                                <div className="select">
                                    <select value={pageSize} onChange={(e) => { setPageSize(e.target.value); }}>
                                        {pageSizes.map(size => {
                                            return <option key={size}>{size}</option>
                                        })}
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}