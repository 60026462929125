import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";

export default function Login({ children }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const { authUser, logIn } = useUserAuth();

    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        await logIn(email, password);
        navigate("/home/view");
    };

    useEffect(() => {
        if (authUser) {
        }
    }, [authUser, navigate]);

    return (
        <>
            <h1 className="title is-3">Login</h1>
            <form onSubmit={handleSubmit}>
                <div className="field">
                    <label className="label">Email</label>
                    <div className="control">
                        <input className="input is-danger" type="email" placeholder="Nimagna Email" onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Password</label>
                    <div className="control">
                        <input className="input is-danger" type="password" placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
                    </div>
                </div>
                <div className="control">
                    <button className="button is-primary">Submit</button>
                </div>
            </form>
        </>
    );
};
