import { useEffect, useState } from "react";
import { useDataContext } from '../context/DataContext'
import { useUserAuth } from "../context/UserAuthContext";
import { useTheme } from "../context/ThemeContext";
import { useParams, Link } from 'react-router-dom'
import { useNavigate } from "react-router";
import { userLevels } from "../components/Helpers"
import { Icon } from '@mdi/react';
import { mdiCloudCheckVariantOutline, mdiCloudLockOutline } from '@mdi/js';

export default function Stage({ children }) {
    const { stages, refreshStages, saveStage } = useDataContext();
    const { authUserData } = useUserAuth();
    const { themeColors } = useTheme();

    const [theStage, setTheStage] = useState({});

    const { id, mode } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        refreshStages(false);
    });

    useEffect(() => {
        const updateStage = async () => {

            const filteredStage = stages.filter(
                (stage) => stage.id === id
            );
            if (filteredStage.length !== 1) {
                setTheStage({})
                return;
            }
            const stage = filteredStage[0];
            setPublic(stage.public);
            setFilename(stage.file);
            setMinVersion(stage.minAppVersion);
            setMaxVersion(stage.maxAppVersion);
            setUserLevel(stage.userLevel);
            setTheStage(stage);
        }

        updateStage();
    }, [stages, id]);


    function togglePublic() {
        setPublic(!isPublic);
    }

    function viewMode() {
        return (
            <>
                <h1 className="title is-2">'{theStage?.description}' stage</h1>
                <div className="fixed-grid">
                    <div className="grid">
                        <div className="cell">Public</div>
                        <div className="cell">{
                            theStage?.public ?
                                <Icon path={mdiCloudCheckVariantOutline} size={1} className="mr-2" color="LimeGreen" title="Public stage" />
                                : <Icon path={mdiCloudLockOutline} size={1} className="mr-2" color="red" title="Private stage" />
                        }</div>
                        <div className="cell">Required user level</div>
                        <div className="cell">{theStage?.userLevelString}</div>
                        <div className="cell">File</div>
                        <div className="cell">{theStage?.file}</div>
                        <div className="cell">Minimal app version</div>
                        <div className="cell">{theStage?.minAppVersion !== "0.0.0.0" ? theStage?.minAppVersion : "n/a"}</div>
                        <div className="cell">Maximal app version</div>
                        <div className="cell">{theStage?.maxAppVersion !== "9999.99.99.9999" ? theStage?.maxAppVersion : "n/a"}</div>
                    </div>
                </div>
                <div className="buttons is-right">
                    <button
                        className="button is-primary"
                        onClick={(e) => { navigate("/stages"); }}>
                        Back
                    </button>
                    {
                        authUserData?.isAdmin && <Link className="button is-link is-outlined" to={`/stage/${theStage.id}/edit`}>Edit</Link>
                    }
                </div>
            </>
        );
    }

    const [isPublic, setPublic] = useState(false);
    const [filename, setFilename] = useState("");
    const [minVersion, setMinVersion] = useState("");
    const [maxVersion, setMaxVersion] = useState("");
    const [userLevel, setUserLevel] = useState(0);

    function editMode() {
        return (
            <>
                <h1 className="title is-2">Edit '{theStage?.description}' stage</h1>
                <div className="field">
                    <div className="control">
                        <label className="checkbox">
                            <button className={isPublic ? "button is-success mr-2" : "button is-success is-outlined mr-2"} onClick={togglePublic}><Icon path={mdiCloudCheckVariantOutline} size={1} className="mr-2" color={themeColors.green1} /> Public</button>
                            <button className={isPublic ? "button is-danger is-outlined" : "button is-danger"} onClick={togglePublic}><Icon path={mdiCloudLockOutline} size={1} className="mr-2" color="red" /> Private</button>
                        </label>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Filename</label>
                    <div className="control">
                        <input name="file" className="input" type="text" value={filename} onChange={(e) => setFilename(e.target.value)} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Required User Level</label>
                    <div className="control">
                        <div className="select">
                            <select name="userLevel" value={userLevel} onChange={(e) => setUserLevel(e.target.value)}>
                                {
                                    userLevels().map(level => {
                                        return <option value={level.value} key={level.value}>{level.name}</option>;
                                    })
                                }
                            </select>
                        </div>
                    </div>
                </div>
                <div className="field">
                    <label className="label">Minimal app version</label>
                    <div className="control">
                        <input name="minimalAppVersion" className="input" type="text" value={minVersion} onChange={(e) => setMinVersion(e.target.value)} />
                    </div>
                </div>
                <div className="field">
                    <label className="label">Maximal app version</label>
                    <div className="control">
                        <input name="maximalAppVersion" className="input" type="text" value={maxVersion} onChange={(e) => setMaxVersion(e.target.value)} />
                    </div>
                </div>
                <div className="buttons is-right">
                    <button
                        className="button is-primary"
                        onClick={(e) => {
                            saveStage(theStage.id, isPublic, filename, parseInt(userLevel), minVersion, maxVersion);
                            navigate("/stage/" + theStage.id + "/view");
                        }}>
                        Save
                    </button>
                    <Link className="button is-link is-outlined" to={`/stage/${theStage.id}/view`}>Cancel</Link>
                </div>
            </>
        );
    }

    if (mode === "view") {
        return viewMode();
    } else if (mode === "edit") {
        return editMode();
    }
}