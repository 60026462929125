import React from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
const ProtectedRoute = ({ children }) => {
    const { authUser } = useUserAuth();
    if (!authUser) {
        return <Navigate to="/" />;
    }
    return children;
};

export default ProtectedRoute;
