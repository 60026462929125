import { db } from '../firebase-config';
import { setDoc, doc } from 'firebase/firestore';
import { getUserTypeString } from "../components/Helpers";
import { Notification } from "../context/NotificationContext";

export class StageData {
    // create a StageData object from the raw Firestore stage data
    constructor(uid, rawStageData) {
        this.id = uid;
        this.description = this.id;
        this.setValues(rawStageData.public, rawStageData.file, rawStageData.user_level, rawStageData.minimal_app_version, rawStageData.maximal_app_version);
    }

    setValues(isPublic, filename, userLevel, minVersion, maxVersion) {
        this.public = isPublic;
        this.file = filename;
        this.userLevel = userLevel;
        this.userLevelString = getUserTypeString(this.userLevel);
        this.maxAppVersion = minVersion;
        this.minAppVersion = maxVersion;
    }

    // update values in Firebase and in the object itself
    async update(isPublic, filename, userLevel, minVersion, maxVersion) {
        try {
            // write to Firebase
            const result = await setDoc(doc(db, "stages", this.id), {
                public: isPublic,
                file: filename,
                user_level: userLevel,
                minimal_app_version: minVersion,
                maximal_app_version: maxVersion,
            }, { merge: true });
            // update object values
            this.setValues(isPublic, filename, userLevel, minVersion, maxVersion);
            return result;
        } catch (e) {
            throw new Notification("error", "Save stage", "Error saving stage: " + e);
        }
    }
}