
import { Icon } from '@mdi/react';
import { mdiArrowTopRightThinCircleOutline, mdiArrowBottomRightThinCircleOutline, mdiArrowRightThinCircleOutline } from '@mdi/js';

export const Year = () => {
    var d = new Date();
    return (
        <>
            {d.getFullYear()}
        </>
    );
};

const userLevelMap = [
    {
        "value": 1,
        "name": "*Basic",
        "level": "Free"
    }, {
        "value": 2,
        "name": "Trial",
        "level": "Free"
    }, {
        "value": 5,
        "name": "Free",
        "level": "Free"
    }, {
        "value": 10,
        "name": "*Advanced",
        "level": "Paid"
    }, {
        "value": 11,
        "name": "Starter",
        "level": "Paid"
    }, {
        "value": 50,
        "name": "*Premium",
        "level": "Paid"
    }, {
        "value": 51,
        "name": "Pro",
        "level": "Paid"
    }, {
        "value": 100,
        "name": "Developer",
        "level": "Internal"
    }
];

export const userLevels = () => {
    return userLevelMap;
}

export const getUserTypeString = (userType) => {
    const level = userLevelMap.find((level) => { return level.value === userType });
    return level ? level.name : "Unknown";
};

export const getUserTypeLevel = (userType) => {
    const level = userLevelMap.find((level) => { return level.value === userType });
    return level ? level.level : "Unknown";
};

export const getUserScoreText = (scoreValue) => {
    var userScoreText = "VeryRareUser"
    if (scoreValue >= 1) {
        userScoreText = "HighValueUser";
    }
    else if (scoreValue >= 0.1) {
        userScoreText = "SeldomUser";
    }
    return userScoreText;
};

export const getUserScoreTag = (scoreText) => {
    switch (scoreText) {
        case "HighValueUser":
            return <><Icon path={mdiArrowTopRightThinCircleOutline} size={1} className="mr-2" color="green" title="High value user" /></>;
        case "SeldomUser":
            return <><Icon path={mdiArrowRightThinCircleOutline} size={1} className="mr-2" color="orange" title="Seldom user" /></>;
        case "VeryRareUser":
            return <><Icon path={mdiArrowBottomRightThinCircleOutline} size={1} className="mr-2" color="red" title="Rare user" /></>;
        default:
            return <></>;
    }
};
