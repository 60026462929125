import { initializeApp } from "firebase/app";
import { getAuth, connectAuthEmulator } from "firebase/auth";
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { connectStorageEmulator, getStorage } from "firebase/storage";

// Firebase configurations
const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.firebaseapp.com`,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}.appspot.com`,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	cloudFunctionsUrl: `https://europe-west6-${process.env.REACT_APP_FIREBASE_PROJECT_ID}.cloudfunctions.net/`,
};
// initialize firebase
try {
	initializeApp(firebaseConfig);
} catch (error) {
	console.error("Firebase initialization error:", error);
}
export const db = getFirestore();
export const auth = getAuth();
export const storage = getStorage();
export const storageBucketUrl = `gs://${firebaseConfig.storageBucket}/`;
export const inProductiveEnvironment =
	firebaseConfig.projectId === "nimagna-ag";
const useEmulatorLocally = false;

if (inProductiveEnvironment) {
	console.log("Productive environment!");
} else {
	console.log(`Test environment using ${firebaseConfig.projectId}`);
	if (useEmulatorLocally) {
		console.log("########## SIMULATOR ############");
		connectFirestoreEmulator(db, "127.0.0.1", 8080);
		connectAuthEmulator(auth, "http://127.0.0.1:9099");
		connectStorageEmulator(storage, "127.0.0.1", 9199);
	}
}
