import { createContext, useContext, useState } from "react";

/**
 * The Notification context provides information about notifications to the user
 */

const notificationContext = createContext();

export class Notification {
    constructor(type, title, description) {
        this.type = type;
        this.title = title;
        this.description = description;
    }
}

export function NotificationContextProvider({ children }) {

    const [notification, setNotification] = useState(null);

    function hideNotification() {
        setNotification(null);
    }

    return (
        <notificationContext.Provider
            value={{
                notification, setNotification, hideNotification
            }}
        >
            {children}
        </notificationContext.Provider>
    );
}

export function useNotificationContext() {
    return useContext(notificationContext);
}
