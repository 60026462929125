import { useEffect, useState } from "react";
import { useUserAuth } from "../context/UserAuthContext";
import { useTheme } from "../context/ThemeContext";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from "react-router";
import { Icon } from "@mdi/react";
import {
	mdiCheckCircleOutline,
	mdiAlertCircleOutline,
	mdiAccountEditOutline,
	mdiLogout,
	mdiEmailNewsletter,
} from "@mdi/js";

export default function Home({ children }) {
	const {
		authUser,
		authUserData,
		saveAuthUser,
		resendEmailVerification,
		logOut,
	} = useUserAuth();
	const { themeColors } = useTheme();
	const { mode } = useParams();
	const navigate = useNavigate();

	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [hasNewsletter, setHasNewsletter] = useState(false);

	useEffect(() => {
		setFirstName(authUserData?.firstName);
		setLastName(authUserData?.lastName);
		setHasNewsletter(authUserData?.hasNewsletter);
	}, [authUserData]);

	if (mode === "view") {
		return (
			<>
				<div className="section">
					<div className="block">
						<h1 className="title is-1 has-text-centered">
							Nimagna Admin Center
						</h1>
						<div className="block has-text-centered">
							Welcome to the Nimagna Admin Center.
						</div>
					</div>
					<article className="message">
						<div className="message-header">
							<p className="has-text-light">
								Logged in as {authUserData ? authUserData.displayName : ""}
							</p>
							<button className="button is-outlined is-link" onClick={logOut}>
								<Icon path={mdiLogout} className="mr-2" size={1} />
								Logout
							</button>
						</div>
						<div className="message-body">
							<div className="fixed-grid has-4-cols">
								<div className="grid">
									<div className="cell">Email</div>
									<div className="cell is-col-span-3">
										<div className="grid">
											<div className="cell">
												{authUserData?.emailAddress}
												{authUserData?.emailVerified ? (
													<Icon
														path={mdiCheckCircleOutline}
														size={0.75}
														className="mr-2"
														color={themeColors.green1}
													/>
												) : (
													<Icon
														path={mdiAlertCircleOutline}
														size={0.75}
														className="mr-2"
														color="orange"
													/>
												)}
											</div>
											{!authUserData?.emailVerified && (
												<div className="cell">
													<button
														className="button is-link is-success"
														onClick={resendEmailVerification}
													>
														Resend email verification
													</button>
												</div>
											)}
										</div>
									</div>
									<div className="cell">Name</div>
									<div className="cell is-col-span-3">
										{authUserData?.firstName} {authUserData?.lastName}
									</div>
									<div className="cell">Level</div>
									<div className="cell is-col-span-3">
										{authUserData ? authUserData.userTypeString : ""}
									</div>
									<div className="cell">Newsletter</div>
									<div className="cell is-col-span-3">
										<span
											className={
												"tag is-medium " +
												(authUserData?.hasNewsletter
													? "is-success"
													: "is-danger")
											}
										>
											<Icon
												path={mdiEmailNewsletter}
												size={1}
												className="mr-2"
											/>
											{authUserData?.hasNewsletter
												? "Subscribed to newsletter"
												: "Not subscribed to newsletter"}
										</span>
									</div>
								</div>
							</div>
							<div className="buttons is-right">
								<Link to="/home/edit" className="button is-link is-outlined">
									<Icon
										path={mdiAccountEditOutline}
										size={1}
										className="mr-2"
									/>{" "}
									Edit
								</Link>
							</div>
						</div>
					</article>
				</div>
			</>
		);
	}
	if (mode === "edit") {
		return (
			<>
				<div className="block">
					<h1 className="title is-2">Edit personal details</h1>
				</div>
				<div className="field">
					<label className="label">Email address</label>
					<div className="control ">
						<div className="cell">
							{authUserData.emailAddress}
							{authUser.emailVerified ? (
								<Icon
									path={mdiCheckCircleOutline}
									size={0.75}
									className="mr-2"
									color={themeColors.green1}
								/>
							) : (
								<Icon
									path={mdiAlertCircleOutline}
									size={0.75}
									className="mr-2"
									color="orange"
								/>
							)}
						</div>
					</div>
				</div>
				<div className="field">
					<label className="label">First name</label>
					<div className="control">
						<input
							name="firstName"
							className="input"
							type="text"
							value={firstName}
							onChange={(e) => setFirstName(e.target.value)}
						/>
					</div>
				</div>
				<div className="field">
					<label className="label">Last name</label>
					<div className="control">
						<input
							name="lastName"
							className="input"
							type="text"
							value={lastName}
							onChange={(e) => setLastName(e.target.value)}
						/>
					</div>
				</div>
				<div className="field">
					<div className="control">
						<button
							className={
								hasNewsletter
									? "button is-success mr-2"
									: "button is-danger is-outlined mr-2"
							}
							onClick={(e) => {
								setHasNewsletter(!hasNewsletter);
							}}
						>
							<Icon path={mdiEmailNewsletter} size={1} className="mr-2" />{" "}
							{authUserData.hasNewsletter
								? /* user is subscribed */
								  hasNewsletter
									? "Remain subscribed"
									: "Unsubscribe"
								: /* user is not subscribed */
								hasNewsletter
								? "Subscribe"
								: "Remain unsubscribed"}
						</button>
					</div>
				</div>
				<div className="level">
					<div className="level-left"></div>
					<div className="level-right">
						<div className="level-item">
							<button
								className="button mr-3 is-primary"
								onClick={(e) => {
									saveAuthUser(
										authUserData.id,
										firstName,
										lastName,
										hasNewsletter
									);
									navigate("/home/view");
								}}
							>
								Save
							</button>
							<button
								className="button is-link is-outlined"
								onClick={() => navigate(-1)}
							>
								Cancel
							</button>
						</div>
					</div>
				</div>
			</>
		);
	}
}
