import { useEffect, useMemo, useState } from "react";
import { useDataContext } from '../context/DataContext';
import { useUserAuth } from "../context/UserAuthContext";
import { useTheme } from "../context/ThemeContext";
import { useNavigate } from "react-router";
import { useParams, Link } from "react-router-dom";
import { Icon } from '@mdi/react';
import { mdiApple, mdiCloudCheckVariantOutline, mdiCloudLockOutline, mdiMicrosoftWindows, mdiAlertOutline, mdiEyeOutline, mdiPencil, mdiDeleteOutline } from '@mdi/js';

/**
 * Displays an overview of the releases.
 */

export default function Releases({ children }) {
    var now = new Date();

    const { mode } = useParams();
    const { releases, refreshReleases, createRelease } = useDataContext();
    const { authUserData } = useUserAuth();
    const { themeColors } = useTheme();

    const navigate = useNavigate();

    const [stringFilterValue, setStringFilterValue] = useState('');
    const [publicFilter, setPublicFilter] = useState(true);
    const [year, setYear] = useState(now.getFullYear());
    const [years, setYears] = useState([]);

    const filteredReleases = useMemo(() => {
        return releases.filter(release => {
            return (release.fullVersion?.toLowerCase().includes(stringFilterValue) || release.dateString?.toLowerCase().includes(stringFilterValue))
                && (!publicFilter || release.active) && release.date.getFullYear() === year;
        })
    }, [releases, stringFilterValue, publicFilter, year]);

    useEffect(() => {
        refreshReleases();
    });

    useEffect(() => {
        // collect all years
        const years_set = new Set();
        releases.forEach(release => {
            var releaseYear = release.date.getFullYear();
            years_set.add(releaseYear);
        });
        setYears(Array.from(years_set));
    }, [releases])

    function listMode() {
        return (
            <>
                <div className="section">
                    <div className="block">
                        <h1 className="title is-1 has-text-centered">Releases</h1>
                        <div className="block has-text-centered">All Nimagna releases.</div>
                    </div>
                    <div className="block">
                        <div className="level">
                            <div className="level-left">
                                <div className="level-item">

                                    <div className="field is-grouped">
                                        <div className="control">
                                            <input className="input" type="text" placeholder="Search" onChange={(e) => { setStringFilterValue(e.target.value.toLowerCase()); }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="level-right ">
                                <div className="level-item">
                                    <button className={!publicFilter ? "button is-active is-primary mr-3" : "button  mr-3"} onClick={(e) => { setPublicFilter(!publicFilter); }}>Internal</button>
                                </div>
                            </div>
                            <div className="level-right">
                                <div className="level-item">
                                    {
                                        // years button to filter
                                        years.map(buttonYear => {
                                            return <button className={"button mr-2" + ((buttonYear === year) && " is-active is-primary")} onClick={() => { setYear(buttonYear); }} m="1" key={buttonYear}>{buttonYear}</button>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block">
                        <table className="table is-hoverable is-fullwidth">
                            <thead key="head">
                                <tr key="headtr">
                                    <th>
                                        Public
                                    </th>
                                    <th>
                                        Version
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                    <th>
                                    </th>
                                </tr>
                            </thead>
                            <tbody key="body">
                                {
                                    filteredReleases.map(release => {
                                        return <tr key={release.id} id={release.id} className={release?.active && "has-background-success-soft"}>
                                            <td className="is-clickable" onClick={(e) => { navigate(`/release/${release.id}/view`); }}>{
                                                release?.active ?
                                                    <Icon path={mdiCloudCheckVariantOutline} size={1} className="mr-2" color={release?.version[2] === "0" ? themeColors.green1 : themeColors.green2} title="Public release" />
                                                    : <Icon path={mdiCloudLockOutline} size={1} className="mr-2" color="red" title="Internal release only" />
                                            }</td>
                                            <td className={"is-clickable " + (release?.version[2] === "0" ? "has-text-weight-bold" : "")} onClick={(e) => { navigate(`/release/${release.id}/view`); }}>{release.fullVersion}</td>
                                            <td className={"is-clickable " + (release?.version[2] === "0" ? "has-text-weight-bold" : "")} onClick={(e) => { navigate(`/release/${release.id}/view`); }}>{release.dateString}</td>
                                            <td className={"is-clickable " + (release?.version[2] === "0" ? "has-text-weight-bold" : "")} onClick={(e) => { navigate(`/release/${release.id}/view`); }}>{release.comment}</td>
                                            <td className="buttons is-right">
                                                <Link to={`/release/${release?.id}/view`} ><Icon path={mdiEyeOutline} size={1} title="View details" /></Link>
                                                {authUserData?.isAdmin && <Link to={`/release/${release?.id}/edit`} ><Icon path={mdiPencil} size={1} title="Edit release" /></Link>}
                                                {authUserData?.isAdmin && <Link to={`/release/${release?.id}/delete`} ><Icon path={mdiDeleteOutline} size={1} title="Delete release" /></Link>}
                                            </td>
                                        </tr>;
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className="buttons is-right">
                        {
                            authUserData?.isAdmin && <div className="block"><Link to="/releases/new" className="button is-danger is-outlined"><span className="has-text-danger"><Icon path={mdiAlertOutline} size={1} className="mr-2" /></span> Add new Release</Link></div>
                        }
                    </div>
                </div >
            </>
        );
    }


    const [newReleaseVersion, setNewReleaseVersion] = useState("");
    const [newReleasePublic, setNewReleasePublic] = useState(false);
    const [newReleaseWindows, setNewReleaseWindows] = useState(true);
    const [newReleaseMacArm, setNewReleaseMacArm] = useState(true);
    const [newReleaseMacIntel, setNewReleaseMacIntel] = useState(true);
    const [newReleaseNimagna, setNewReleaseNimagna] = useState(true);
    const [newReleasePeoplefone, setNewReleasePeoplefone] = useState(true);

    function newMode() {
        return (
            <>
                <div className="columns mt-1">
                    <div className="column is-10 is-offset-1">
                        <h1 className="title is-1 has-text-centered">Add new Release</h1>
                        <div className="block has-text-centered has-text-danger">The according installers must be uploaded before!</div>
                        <div className="field">
                            <label className="label">Version</label>
                            <div className="control">
                                <input className="input" type="text" placeholder="x.y.z.v" value={newReleaseVersion} onChange={(e) => setNewReleaseVersion(e.target.value)} />
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Availability</label>
                            <div className="control">
                                <button className={newReleasePublic ? "button is-success mr-2" : "button is-success is-outlined mr-2"} onClick={(e) => { setNewReleasePublic(true); }}><Icon path={mdiCloudCheckVariantOutline} size={1} className="mr-2" color={themeColors.green1} /> Public</button>
                                <button className={newReleasePublic ? "button is-danger is-outlined" : "button is-danger"} onClick={(e) => { setNewReleasePublic(false); }}><Icon path={mdiCloudLockOutline} size={1} className="mr-2" color="red" /> Private</button>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Operating System(s)</label>
                            <div className="control">
                                <button className={newReleaseWindows ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setNewReleaseWindows(!newReleaseWindows); }}><Icon path={mdiMicrosoftWindows} size={1} className="mr-2" /> Windows</button>
                                <button className={newReleaseMacArm ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setNewReleaseMacArm(!newReleaseMacArm); }}><Icon path={mdiApple} size={1} className="mr-2" /> macOS arm</button>
                                <button className={newReleaseMacIntel ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setNewReleaseMacIntel(!newReleaseMacIntel); }}><Icon path={mdiApple} size={1} className="mr-2" /> macOS x86</button>
                            </div>
                        </div>
                        <div className="field">
                            <label className="label">Configuration(s)</label>
                            <div className="control">
                                <button className={newReleaseNimagna ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setNewReleaseNimagna(!newReleasePeoplefone); }}>Nimagna App</button>
                                <button className={newReleasePeoplefone ? "button is-success mr-2" : "button is-danger is-outlined mr-2"} onClick={(e) => { setNewReleasePeoplefone(!newReleasePeoplefone); }}>peoplefone STUDIO</button>
                            </div>
                        </div>
                        <hr />
                        <div className="buttons is-right">
                            <button
                                className="button is-primary"
                                onClick={(e) => {
                                    createRelease(newReleaseVersion, newReleasePublic, newReleaseWindows, newReleaseMacArm, newReleaseMacIntel, newReleaseNimagna, newReleasePeoplefone);
                                    navigate("/releases/list");
                                }}>
                                Submit
                            </button>
                            <button className="button is-link is-outlined" onClick={() => navigate(-1)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    if (mode === "list") {
        return listMode();
    } else if (mode === "new") {
        return newMode();
    };

}