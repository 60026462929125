
import { getUserTypeString, getUserTypeLevel, getUserScoreText, getUserScoreTag } from "../components/Helpers"

// create a user data object from the raw Firestore user data

export class UserData {
    constructor(uid, rawUserData, loginCount, lastLogin, lastServiceLogin) {
        // person related
        this.id = uid;
        this.emailAddress = rawUserData.emailAddress;
        this.isAdmin = rawUserData.isAdmin !== null ? rawUserData.isAdmin : false;
        this.update(
            rawUserData.firstName,
            rawUserData.lastName,
            rawUserData.userCreatedDate.toDate(),
            rawUserData.endOfTrialPeriod?.toDate(),
            rawUserData.userType,
            rawUserData.newsletterStatus ? rawUserData.newsletterStatus === "NewsletterYes" : false,
            rawUserData.allowAspose
        );
        this.emailVerified = (rawUserData.mailchimpEmailStatusTag ? rawUserData.mailchimpEmailStatusTag === "UserVerified" : false);
        // date related
        if (lastLogin === null || lastLogin === undefined) {
            this.lastLogin = new Date("2000-01-01");
            this.lastLoginString = "";
        } else {
            this.lastLogin = lastLogin.timestamp.toDate();
            this.lastLoginString = this.lastLogin.toLocaleDateString();
        }
        if (lastServiceLogin === null || lastServiceLogin === undefined) {
            this.lastServiceLogin = new Date("2000-01-01");
            this.lastServiceLoginString = "";
        } else {
            this.lastServiceLogin = lastServiceLogin.timestamp.toDate();
            this.lastServiceLoginString = this.lastServiceLogin.toLocaleDateString();
        }
        // tags
        this.usingWindows = (rawUserData.windowsUserTag && (rawUserData.windowsUserTag === "UsingWindows"));
        this.windowsTag = (this.usingWindows ? <><span className="tag is-info">Windows</span></> : <></>);
        this.usingMacOS = (rawUserData.macosUserTag && (rawUserData.macosUserTag === "UsingMacOS"));
        this.macosTag = (this.usingMacOS ? <><span className="tag is-info">macOS</span></> : <></>);
        // other
        this.lastVersion = lastLogin?.version;
        this.loginCount = loginCount;
        this.userClass = rawUserData.userClass;
        this.userClassTag = getUserScoreTag(this.userClass);
        // new user class
        if (this.lastLogin !== null) {
            const today = new Date();
            const dayLength = 24 * 60 * 60 * 1000;
            let lastUseDate = this.lastLogin;
            let daysFromCreateToLastLogin = Math.floor(Math.abs(lastUseDate - this.createdDate) / dayLength);
            let daysSinceLastUseValue = Math.floor((today - lastUseDate) / dayLength) + 1;
            this.newUserClassValue = daysFromCreateToLastLogin > 2 ? 100 * this.loginCount / (daysFromCreateToLastLogin * daysSinceLastUseValue) : 0;
            this.newUserClass = getUserScoreText(this.newUserClassValue);
            this.newUserClassTag = getUserScoreTag(this.newUserClass);
        }
    }

    // update method used in the constructor above and in saveUser to replace the user editable values
    update(firstName, lastName, createdDate, endOfTrialPeriodDate, userLevel, newsletterFlag, allowAspose) {
        this.firstName = firstName;
        this.lastName = lastName;
        this.fullName = `${firstName} ${lastName}`;
        this.displayName = (this.fullName.length > 1 ? this.fullName : this.emailAddress);
        // created and end of trial
        this.createdDate = createdDate;
        this.createdDateString = this.createdDate.toLocaleDateString();
        this.endOfTrialPeriod = endOfTrialPeriodDate;
        this.endOfTrialPeriodString = this.endOfTrialPeriod?.toLocaleDateString();
        // user type
        this.userType = userLevel;
        this.userTypeString = this.isAdmin ? "Administrator" : getUserTypeString(this.userType);
        this.userTypeLevel = getUserTypeLevel(this.userType);
        // newsletter
        this.hasNewsletter = newsletterFlag;
        this.newsletterTag = (this.hasNewsletter ? <><span className="tag is-success">NL</span></> : <><span className="tag is-danger">No NL</span></>);
        // aspose
        this.allowAspose = allowAspose ? allowAspose : true;
    }
}
