import { useEffect, useMemo, useState } from "react";
import { useDataContext } from '../context/DataContext.js'
import { useUserAuth } from "../context/UserAuthContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { Icon } from '@mdi/react';
import { mdiCloudCheckVariantOutline, mdiCloudLockOutline, mdiSortAscending, mdiSortDescending, mdiPencil, mdiEyeOutline } from '@mdi/js';
/**
 * Displays an overview of the stages.
 */

export default function Stages({ children }) {
    const { stages, refreshStages } = useDataContext();
    const { authUserData } = useUserAuth();

    const [stringFilterValue, setStringFilterValue] = useState('');
    const [publicFilter, setPublicFilter] = useState(true);
    const [sortBy, setSortBy] = useState("name");
    const [sortDirection, setSortDirection] = useState(true);

    const navigate = useNavigate();

    // filtering the stages
    const filteredStages = useMemo(() => {
        return stages.filter(stage => {
            return (stage.description?.toLowerCase().includes(stringFilterValue))
                && (publicFilter || stage.public);
        })
    }, [stages, stringFilterValue, publicFilter]);

    // sorting the stages
    const sortedStages = useMemo(() => {
        let sortedArray = filteredStages.toSorted();
        if (sortBy === "name") {
            sortedArray.sort((l, r) => {
                // sort alphabetically by display name
                let x = l.description.toLowerCase();
                let y = r.description.toLowerCase();
                if (x < y) { return -1; }
                if (x > y) { return 1; }
                return 0;
            });
        } else if (sortBy === "level") {
            sortedArray.sort((l, r) => {
                // sort numerically by user level
                return l.userLevel - r.userLevel;
            });
        } else if (sortBy === "access") {
            sortedArray.sort((l, r) => {
                // sorted by public
                return r.public ? 1 : -1;
            });
        }
        if (sortDirection === false) {
            sortedArray.reverse();
        }
        return sortedArray;
    }, [filteredStages, sortBy, sortDirection]);

    useEffect(() => {
        refreshStages(false);
    });

    function resetSortBy(type) {
        setSortDirection(true);
        setSortBy(type);
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const sortTypes = ["access", "name", "level"];

    return (
        <>
            <div className="section">
                <div className="block">
                    <h1 className="title is-1 has-text-centered">Stages</h1>
                    <div className="block has-text-centered">All Nimagna stages.</div>
                </div>
                <div className="level">
                    <div className="level-left">
                        <div className="level-item">
                            <div className="control">
                                <input className="input" type="text" placeholder="Search" onChange={(e) => { setStringFilterValue(e.target.value.toLowerCase()); }} />
                            </div>
                        </div>
                        <div className="level-item">
                            <button className={"button is-primary " + (publicFilter ? "is-active" : "is-outlined")} onClick={(e) => { setPublicFilter(!publicFilter); }}>Private</button>
                        </div>
                    </div>
                </div>
                <table className="table is-hoverable is-fullwidth">
                    <thead key="head">
                        <tr key="headtr">
                            {
                                sortTypes.map(type => {
                                    return <th key={type} className={"is-clickable" + (sortBy === type ? " is-underlined" : "")} onClick={() => { sortBy === type ? setSortDirection(!sortDirection) : resetSortBy(type) }}>
                                        <div className="level">
                                            <div className="level-left">
                                                <div className="level-item">{capitalizeFirstLetter(type)}</div>
                                                {sortBy === type && <div className="level-item"><Icon path={sortDirection ? mdiSortAscending : mdiSortDescending} size={0.75} /></div>}
                                            </div>
                                        </div>
                                    </th>
                                })
                            }
                            <th>
                                File
                            </th>
                            <th>
                            </th>
                        </tr>
                    </thead>
                    <tbody key="body">
                        {
                            sortedStages.map(stage => {

                                return <tr key={stage.id} id={stage.id} className={stage?.public ? "has-background-success-soft" : ""}>
                                    <td className="is-clickable" onClick={(e) => { navigate("/stage/" + stage.id + "/view"); }}>{
                                        stage?.public ?
                                            <Icon path={mdiCloudCheckVariantOutline} size={1} className="mr-2" color="LimeGreen" title="Public stage" />
                                            : <Icon path={mdiCloudLockOutline} size={1} className="mr-2" color="red" title="Private stage" />
                                    }</td>
                                    <td className="is-clickable" onClick={(e) => { navigate("/stage/" + stage.id + "/view"); }}>{stage.description}</td>
                                    <td className="is-clickable" onClick={(e) => { navigate("/stage/" + stage.id + "/view"); }}>{stage.userLevelString}</td>
                                    <td className="is-clickable" onClick={(e) => { navigate("/stage/" + stage.id + "/view"); }}>{stage.file}</td>
                                    <td className="buttons is-right">
                                        <Link to={`/stage/${stage?.id}/view`} ><Icon path={mdiEyeOutline} size={1} title="View details" /></Link>
                                        {authUserData?.isAdmin && <Link to={`/stage/${stage?.id}/edit`} ><Icon path={mdiPencil} size={1} title="Edit release" /></Link>}
                                    </td>
                                </tr>;
                            })
                        }
                    </tbody>
                </table>
            </div>
        </>
    );
}